import React from 'react';
import './privacy-policy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <div className="content column">
        <h1>Data Protection Policy</h1>
        <h3>Policy Content</h3>

        <p><b>1. The data that I process and how it flows into, through and out my business.</b></p>
        <p>
          Data comes into my business in 4 ways:
        </p>
        <ul>
          <li>Via email messages to me from potential clients and clients that have my email</li>
          <li>Via text messages</li>
          <li>Via my website</li>
          <li>Via Facebook Messenger</li>
        </ul>
        
        <p>
          It flows through my business via:
        </p>
        <ul>
          <li>My personal smart phone via which only clients  names and contact numbers are kept. A full paper file is then opened up from the clients first consultation.
            The information does not flow out of my business.</li>
        </ul>
        

        <p><b>2. The personal data I hold, where it came from, who I share it with and what I do with it.</b></p>
        <p>Information Asset Register</p>
        <ul>
          <li>I hold personal information about my clients that they have given me.</li>
          <li>This includes name, address, contact details, and, where appropriate, age.  I also hold health and 
            wellbeing information about them which I collect from them at their first consultation.
          </li>
          <li>I hold information about each treatment that they receive from me.</li>
          <li>I don't share this information with anyone.</li>
          <li>I use the information I have to inform my clients and provide them with any appropriate advice within the realms of the treatment, my professional experience and qualifications.</li>
          <li>I keep delivery information solely to fulfill the delivery of orders from the shop.</li>
          <li>I keep all data for:
            <ul>
              <li>claims occuring insurance: for which I am required to keep my records for 7 years after the last treatment</li>
              <li>law regarding children's records: for which I am required to keep my records until the child is 25, or if 17 when treated then until they are 26.</li>
            </ul>
            Your data will not be transferred outside of the EU without your consent.
          </li>
        </ul>

        <p><b>3. The lawful bases for me to process personal data and special categories of data.</b></p>
        <p>I process the personal data under:</p>
        <ul>
          <li><b>Legitimate interest:</b> I am required to retain the information about my clients in order to provide them with the best possible treatment options and advice.</li>
          <li><b>Special Category Data - Health Related:</b> I process under special category data, therefore the additional condition under which I hold and use this information is for me to fulfil my role as a healthcare practitioner, bound under the AoR Confidentiality as defined in their Codes of Practice and Ethics. www.aor.org.uk</li>
        </ul>
        

        <p><b>4. Privacy Notice</b></p>
        <p>
          Individuals need to know that their data is collected, why it is processed and who it is shared with.  This information in included in my privacy notice on my website and within any forms or letters I send to individuals, including at my first consultation with my client.
          <br />
          I have written a privacy notice for my website and for my clients.
        </p>

        <p><b>5. Processes to recognise and respond to individuals' requests to access their personal data.</b></p>
        <p>
          All individuals will need to submit a written request to access their personal data - either by email or by letter.  I will provide that information without delay and at least within one calendar month of receipt. I can extend this period by a further two months for complex or numerous requests (in which case the individual will be informed and given an explanation).
          <br />
          I will identify the client using reasonable means, which because of the special category under which I process data, will be photographic ID.
          <br />
          I will keep a record of any requests to access personal data.
        </p>

        <p><b>6. Processes to ensure that the personal data I hold remains accurate and up to date.</b></p>
        <p>
          I will ensure that client information is kept up to date during our treatments, and will update client information as I am informed of any changes.
          <br />
          Once a year I will also have a wholesale review of all data.
        </p>

        <p><b>7. Schedule to dispose of various categories of data, and its secure disposal.</b></p>
        <p>
          Once a year I will review my client information and will place dormant clients in a separate file.  This will be assessed each month to ensure that data that is no longer required to be  destroyed securely. 
        </p>

        <p><b>8. Procedures to respond to an individual’s request to restrict the processing of their personal data.</b></p>
        <p>
          As I only hold data in order to provide treatments, I cannot envisage a situation where I would receive a request to restrict their processing of an individual’s personal data.  However, if I do receive a request I will respond as quickly as possible, and within one calendar month, explaining clearly what I currently do with their data and that I will continue to hold their data but will ensure that it is not processed. 
        </p>

        <p><b>9. Processes to allow individuals to move, copy or transfer their personal data from one IT environment to another in a safe and secure way, without hindrance to usability.</b></p>
        <p>
          Should clients wish their data to be copied or transferred I would work with the client to ensure that this is done in a way that was most appropriate for them - for example this could be an electronic summary of treatment received and progress made, copies of individual treatment records.  I do not hold any treatment information electronically.
        </p>

        <p><b>10. Procedures to handle an individual’s objection to the processing of their personal data.</b></p>
        <p>
          I will inform my clients of their right to object “at the point of first communication” and have clearly laid this out in my privacy notice.
        </p>

        <p><b>11. Processing operations that constitute automated decision making.</b></p>
        <p>
          I do not have any processing operations that constitute automated decision making and therefore, do not currently require procedures in place to deal with the requirements.  This right is, however, included in  my privacy statement.
        </p>

        <p><b>12. Data Protection Policy</b></p>
        <p>
          This document forms my data protection policy and shows how I comply with GDPR.
          I was advised by the GDPR that I do not have to register with them as I do not hold any personal or medical information about my clients on a computer.
        </p>

        <p><b>13. Effective and structured information risks management </b></p>
        <p>
          The risks associated with my data, and how that risk is managed is as follows:
        </p>
        <ul>
          <li>Theft of electronic devices - both have password locks on all electronic devices which are changed regularly and are not shared with anyone. </li>
          <li>Break in to office - all my paper files are stored in locked filing cabinet in a locked room.  No one else has the key but me.</li>
          <li>Theft of paper file while at home -  all work taken home with me is kept in my office, and my home is fitted with a burglar alarm. Consideration does need to be given to a second locked cabinet at home.</li>
        </ul>

        <h2>Your Rights</h2>
        <p>
          GDPR gives you the following rights:
        </p>
        <ul>
          <li>
            The right to be informed:
            <br />
            To know how your information will be held and used (this notice).
          </li>
          <li>
            The right of access:
            <br />
            To see your therapists records of your personal information so you know what is held about you and can verify it.
          </li>
          <li>
            The right of rectification:
            <br />
            To tell your therapist to make changes to your personal information it is incorrect or incomplete.
          </li>
          <li>
            The right to erasure (also “the right to be forgotten”):
            <br />
            For you to request your therapist to erase any information they hold about you.
          </li>
          <li>
            The right to restrict processing of personal data:
            <br />
            You have the right to restrict limits on how your therapist uses your personal information
          </li>
          <li>
            The right to data portability:
            <br />
            under certain circumstances you can request a copy of your personal information held electronically so you can use it in other systems.
          </li>
          <li>
            The right to object:
            <br />
            To be able to tell your therapist you don’t want them to use certain parts of your information, or only use it for certain purposes
          </li>
        </ul>

        <h2>Therapist's Rights</h2>
        <p>
          Please note :
          If you don’t agree to your therapist keeping records of information about you and your treatments or if you don’t allow them to use the information in the way they need to for treatments, the therapist may not be able to treat you.
          Your therapist has to keep records of treatment for a certain period as described above, which may mean that even if you ask for them to erase any details about you, they have to keep these details until after that period has passed.
          Your therapist can move their records between computers and IT systems, as long as your details are protected from being seen by others without your permission.
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicy;