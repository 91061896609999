import React from 'react';
import { testimonialBanner, testimonialBannerLoad } from '../../assets/images';
import { SVGIcon } from '../../assets/icons';
import './testimonials.css';
import { Banner } from '../reuseable-components/reuseable-components';

function Testimonials(props) {
  function renderThumbnailIcon(gender) {
    if (gender === 'female') {
      return (
        <SVGIcon name="woman" width={70} fill="#6F1D00" stroke="#6F1D00" />
      )
    }
    return (
      <SVGIcon name="man" width={70} fill="#6F1D00" stroke="#6F1D00" />
    )
  }

  return (
    <div className="testimonials column">
      <Banner title="Testimonials" src={testimonialBanner} preview={testimonialBannerLoad} />
      <div className="content column border-box">
        {props.testimonials.map((item, key) => (
          <div key={key} className="testimonial">
            <div className="thumbnail">
              {renderThumbnailIcon(item.gender)}
            </div>
            <div className="quote">
              <SVGIcon name="quote" width={20} fill="#6F1D00" style={{paddingRight: '16px'}} />
              {item.quote}
              <SVGIcon name="closed-quote" width={20} fill="#6F1D00" style={{paddingLeft: '16px'}} />
              <h3>{item.name} <span>{item.from}</span></h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Testimonials;