import React, { useState, useEffect } from 'react';
import { CONTACT, SHOPPING_BAG, HOME } from '../../constants/routes.js';
import { UseWindowSize } from '../hooks/reuseable-hooks.js';
import {
  Link,
  useLocation
} from "react-router-dom";
import { FirebaseConsumer } from '../../config/firebase';
import {logoType} from '../../assets/images';
import { ShoppingBag, ChevronDown, Menu, X, Facebook, Instagram } from 'react-feather';
import './header.css';

function Dropdown(props) {
  const [listOpen, setListOpen] = useState(false);
  return (
    <div
      className="dd-wrapper"
      onMouseEnter={() => setListOpen(true)}
      onMouseLeave={() => setListOpen(false)}
    >
      <p className="primary-font header-link">
        {props.title}
        <ChevronDown size={13} />
      </p>
      {listOpen && 
        <div className="dd-list shadow">
          {props.list.map((item) => (
            <Link className="dd-list-item secondary-font hover-link" key={item.key} to={item.to}>
              {item.title}
            </Link>
          ))}
        </div>
      }
    </div>
  )
}

function fixedHeader() {
  function renderBagCount(bagCount) {
    if (bagCount > 0) {
      return (
        <div className="bag-count">
          {bagCount}
        </div>
      )
    }
  }

  return(
    <div className="fixed-header">
      <Link to={CONTACT}>
        <button className="primary-button">Contact</button>
      </Link>
      <FirebaseConsumer>
        {value => {
          const bagCount = value.getBagCount();
          return (
            <Link to={SHOPPING_BAG}>
              <button className="primary-button" style={{width: '2.5em'}}>
                {renderBagCount(bagCount)}
                <ShoppingBag size={20} />
              </button>
            </Link>
          )
        }}
      </FirebaseConsumer>
    </div>
  )
}

function MobileLink(props) {
  const [highlight, setHighlight] = useState(false);
  const [listOpen, setListOpen] = useState(false);

  if (props.to) {
    return (
      <Link
        to={props.to}      
        onMouseDown={() => setHighlight(true)}
        onMouseUp={() => setHighlight(false)}
        onMouseLeave={() => setHighlight(false)}
        className={(highlight ? "highlight" : null)}
        style={(props.to === '/shop' ? {backgroundColor: '#922904'} : null)}
      >
        {props.title}
      </Link>
    )
  } else if (props.dropdown) {
    return (
      <div
        className={"mob-dd-wrapper " + (listOpen ? "open" : null) + " " + (highlight ? "highlight" : null)}
        onMouseDown={() => setHighlight(true)}
        onMouseUp={() => setHighlight(false)}
        onMouseLeave={() => setHighlight(false)}
        onClick={() => setListOpen(!listOpen)}
      >
        <p className="primary-font mob-dd-header">
          {props.title}
          <ChevronDown size={20} className="chevron" />
        </p>
        {listOpen && 
            <div className="mob-dd-list">
              {props.dropdown.map((item) => (
                <Link
                  className="mob-dd-list-item" key={item.key}
                  to={item.to}
                  onClick={() => setListOpen(!listOpen)}
                >
                  {item.title}
                </Link>
              ))}
            </div>
          }
      </div>
    )
  }
  return null;
}

function Header(props) {
  const [menuOpen, setMenuOpen] = useState(false);

  const links = props.links;
  const windowSize = UseWindowSize();

  useEffect(() => {
    setMenuOpen(false);
    // document.body.style.overflow = 'scroll';
  }, [useLocation()]);

  function toggleMobileMenu() {
    if (menuOpen) {
      return setMenuOpen(false);
      // return document.body.style.overflow = 'scroll'
    }
    return setMenuOpen(true);
    // return document.body.style.overflow = 'hidden';
  }

  function MenuToggleIcon() {
    if (menuOpen) {
      return (<X size={30} />)
    } else {
      return (<Menu size={30} />)
    }
  }

  if (windowSize.width >= 1024) {
    // Laptop View
    return (
      <header className="header">
        <div className="header-row">
          <div className="social-media-links">
            <a href="https://www.facebook.com/soleartherapy/" rel="noopener norefferer" target="_blank">
              <Facebook size={32} />
            </a>
            <a href="https://www.instagram.com/soleartherapy" rel="noopener norefferer" target="_blank">
              <Instagram size={32} />
            </a>
          </div>
          <Link to={HOME}>
            <img src={logoType} className="logo" alt="logo" />
          </Link>
          {fixedHeader()}
        </div>
        
        <div className="header-row primary-font">
          {
            links.map((item) => {
              if (item.to) {
                return (
                  <Link
                    key={item.key}
                    to={item.to}
                    className="header-link hover-link"
                  >
                    {item.title}
                  </Link>
                )
              } else if (item.dropdown) {
                return (
                  <Dropdown
                    key={item.key}
                    title={item.title}
                    list={item.dropdown}
                  />
                )
              }
              return null;
            })
          }
        </div> 
      </header>
    )
  }
  // Mobile View
  return (
    <header className="header">
      <div className="header-row">
        {fixedHeader()}
      </div>
      <div className="header-row content">
        <Link to={HOME}>
          <img src={logoType} className="logo" alt="logo" />
        </Link>
        <div
          className="menu-icon"
          onClick={() => toggleMobileMenu()}
        >
          {MenuToggleIcon()}
        </div>
      </div>
      {menuOpen &&
        <div className="menu-list primary-font">
          {
            links.map((item) => {
                return (
                  <MobileLink
                    key={item.key}
                    to={item.to}
                    title={item.title}
                    dropdown={item.dropdown}
                  />
                )
              }
            )
          }
          <div className="row mob-social-media-links">
            <a href="https://www.facebook.com/soleartherapy/" rel="noopener norefferer" target="_blank">
              <Facebook size={24} />
            </a>
            <a href="https://www.instagram.com/soleartherapy" rel="noopener norefferer" target="_blank">
              <Instagram size={24} />
            </a>
          </div>
        </div>
      }
    </header>
  );
}

export default Header;