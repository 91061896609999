import React, {Component} from 'react';
import app from "firebase/app";
import 'firebase/auth';
import 'firebase/database';
import { FirebaseConfig } from "./keys";
const FirebaseContext = React.createContext();

// const products = [
//   {
//     description: ["Formulated by our herbalist specifically for the skin our Healing Balm offers powerful soothing, regenerative, anti-ageing botanicals designed to relieve the symptoms of; eczema, psoriasis, dermatitis, rashes, inflamed skin, insect bites, age spots, grazes, cuts, leg ulcers, mild burns, sunburn, dry skin, UV damage, surgery cracked nipples, nappy rash, improve healing time, aid reduction of scarring and stretch marks.",
//       "Rare Earth Oils Healing Balm contains anti-inflammatory properties that help sensitive skin become more resilient, reduce itching and redness and balances the skins biochemistry, whether it is too oily or too dry. Used in Egypt for thousands of years Calendula was known to support natural collagen and elastin growth in the skin. A natural anti-ageing ingredient in any effective skin care, it also helps slow bleeding from a wound or bite.",
//       "Comfrey stimulates the growth of new cells, promotes skin cell regeneration, and reduces tissue degeneration and supports sensitive skin to become more resilient by counteracting environmental factors, dryness and cracking.",
//       "Chickweed is well known for its anti-inflammatory properties to reduce redness and itching. We have added our own Eucalyptus and Sweet Orange essential oils to increase the antiseptic, anti-inflammatory and analgesic properties."
//     ],
//     img: "https://firebasestorage.googleapis.com/v0/b/soleartherapy-b43f7.appspot.com/o/products%2FhealingBalm.png?alt=media&token=66fbc69a-260e-47c8-8306-fc5ab63b33ce",
//     key: 0,
//     name: "Healing Balm",
//     ingredients: [
//       'Oleo Europa (Olive Oil)',
//       'Persea Americana (Avocado Oil)', 
//       'Beeswax',
//       'Calendula Officinale',
//       'Symphytum Officinale',
//       'Stellaria Media Vill (Chickweed)',
//       'Essential Oils of Orange',
//       'Narrow Leaf Ironbark'
//     ],
//     sizes: [
//       {
//         key: 0,
//         price: 10,
//         size: "15ml",
//         stock: 10
//       },
//       {
//         key: 1,
//         price: 25,
//         size: "35ml",
//         stock: 10
//       },
//       {
//         key: 2,
//         price: 40,
//         size: "110ml",
//         stock: 10
//       }
//     ]
//   },
//   {
//     description: ["Created from 100% organic pure essential oils our Snuffler is a wonderful way to deliver – instant, effective relief from the symptoms of coughs, cold, flu, sinusitis, rhinitis, hay fever, bronchitis, mild breathing difficulties and congestion.",
//       "Using our own hand harvested Narrow Leafed Ironbark Eucalyptus oil our herbalist combined this with essential oils of; Siberian Fir Needle, Wintergreen and Organic Menthol.",
//       "It is reported both Eucalyptus and Fir Needle have antiviral and antibacterial properties that may reduce the risk of infection and support immunity. If you use the snuffler at work, on a plane or where you have risk exposure to air born germs it may reduce viral and bacterial infections and time taken for illness."
//     ],
//     img: "https://firebasestorage.googleapis.com/v0/b/soleartherapy-b43f7.appspot.com/o/products%2Fsnuffler.png?alt=media&token=00f626ec-d3ef-4fae-a7e4-12028dec6911",
//     key: 1,
//     name: 'Snuffler',
//     ingredients: [
//       "TBC"
//     ],
//     sizes: [
//       {
//         key: 0,
//         price: 8,
//         size: "15ml",
//         stock: 10
//       }
//     ]
//   },
//   {
//     description: ["Combining the ancient knowledge of European, Chinese and Ayuvedic Traditional Herbal Medicine, our Muscle Rub is a unique formula exclusively created by our herbalist. Packed full of botanical extracts and essential oils to create an exceptional alchemy of warming relief with a rich spicy aroma, inducing relaxation and bliss.",
//       "By boosting circulation our formula supports the bodies own healing powers to reduce pain and injury time, and deliver rapid and effective relief from the symptoms of; inflammation, arthritis, sports injury, nerve and muscle pain, sciatica, back pain, neck pain, headaches, muscle spasms, cramps, tight muscles and RSI.",
//       "Our Muscle Rub contains Hypericum (St Johns Wort), made by the monks of Europe for over a thousand years. The stunning blood red oil contains powerful anti-inflammatory botanical oils – hypericin and hyperifin, both of which specifically target nerve pain and inflammation. The Hypericum we use is created by collecting the fresh flowers and infusing them in oil as opposed to oil made from infusing the remainder of the plant, often used for anti-depressants."
//     ],
//     img: "https://firebasestorage.googleapis.com/v0/b/soleartherapy-b43f7.appspot.com/o/products%2FmuscleRub.png?alt=media&token=2e6d4452-976c-4ba3-b97d-33debb24f5c0",
//     key: 2,
//     name: "Muscle Rub",
//     ingredients: [
//       'Oleo Europa (Olive Oil)',
//       'Persea Americana (Avocado Oil)', 
//       'Beeswax',
//       'Rosemary Officinalis (Rosemary)',
//       'Hypericum Perforatum',
//       'Grape Fruit Seed Extract',
//       'plant derived Menthol',
//       'Lavandula Angustifolia (Lavender)',
//       'Piper Nigrum (Black Pepper)',
//       'Wintergreen',
//       'Zingiber Officinale (Ginger)',
//       'Eucalyptus Creba'
//     ],
//     sizes: [
//       {
//         key: 0,
//         price: 10,
//         size: "15ml",
//         stock: 10
//       },
//       {
//         key: 1,
//         price: 25,
//         size: "35ml",
//         stock: 10
//       },
//       {
//         key: 2,
//         price: 40,
//         size: "110ml",
//         stock: 10
//       }
//     ]
//   },
//   {
//     description: ["Rapid and effective relief from the symptoms of pain, injury, bruises, issues with tendons and ligaments, sprains, strains, poor circulation, RSI, arthritis, fractured and bruised bones, crushed toes and fingers, torn cartilage and muscle. Our Bruise Balm is based on organic botanical extracts that were also known to reduce the visible signs of spider and varicose veins.",
//       "The powerful botanical extracts in this formula have been used by European Herbalists for over a thousand years. Arnica boots the circulation to just under the skin, supporting healing and removal of fluid and blood. It is little known that Rosemary works in powerful synergy with Arnica to aid healing and improve circulation. The addition of Comfrey herb,  known as knit bone, supports the healing of tendon, cartilage, bone and muscles. We have included Lavender and Eucalyptus Essential Oils to our Bruise Balm to support pain relief, relax tight muscles and provide antiseptic properties.",
//       "Can be used with the Muscle Rub for added relief."
//     ],
//     img: "https://firebasestorage.googleapis.com/v0/b/soleartherapy-b43f7.appspot.com/o/products%2FbruiseBalm.png?alt=media&token=a77b5835-8f5c-4052-9b07-7fb803e2a17b",
//     key: 3,
//     name: 'Bruise Balm',
//     ingredients: [
//       'Oleo Europa (Olive Oil)',
//       'Persea Americana (Avocado Oil)', 
//       'Beeswax',
//       'Rosemary Officinalis (Rosemary)',
//       'Arnica Montana',
//       'Grapefruit Seed extract',
//       'Symphytum Officinale',
//       'Rosemary Officinalis',
//       'Lavandula Angustifolia (Lavender)',
//       'Eucalyptus Crebra'
//     ],
//     sizes: [
//       {
//         key: 0,
//         price: 10,
//         size: "15ml",
//         stock: 10
//       },
//       {
//         key: 1,
//         price: 25,
//         size: "35ml",
//         stock: 10
//       }
//     ]
//   },
//   {
//     description: ["Containing a unique alchemy of Essential oils with powerful antiseptic, antibacterial and antiviral properties our Chest Rub is formulated to help reduce the symptoms of; coughs, colds, flu, sinusitis, hay fever, sore throat, breathing problems, pneumonia, bronchitis, reduce congestion and the risk of a secondary infection such as pneumonia. Research does indicate that inhalations of these essential oils may markedly improve breathing and function.",
//       "We are excited to include our own Narrow Leafed Ironbark Eucalyptus Oil in our Chest Rub, ensuring the highest medicinal grade. We have added Siberian Fir Needle and other ingredients well known, not only for their antiseptic properties, but also to aid the relief of inflammation and congestion. Our organic White Camphor Oil was used in Chinese and Ayuvedic Traditional Medicine for it’s antiseptic and expectorant treatment.",
//       "Wintergreen consists mainly of methyl salicylate, which acts to suppress the body’s production of prostaglandins, an important aspect of pain and inflammation management."
//     ],
//     img: "https://firebasestorage.googleapis.com/v0/b/soleartherapy-b43f7.appspot.com/o/products%2FchestRub.png?alt=media&token=46ecd893-2fd8-4603-9d3f-d667e84acbe1",
//     key: 4,
//     name: "Chest Rub",
//     ingredients: [
//       'Oleo Europa (Olive Oil)',
//       'Persea Americana (Avocado Oil)', 
//       'Beeswax',
//       'Narrow Leafed Ironbark Eucalyptus',
//       'Fir Needle',
//       'Camphor (white)',
//       'Wintergreen',
//       'Organic Menthol',
//       'Grapefruit Seed Extract'
//     ],
//     sizes: [
//       {
//         key: 0,
//         price: 10,
//         size: "15ml",
//         stock: 10
//       },
//       {
//         key: 1,
//         price: 25,
//         size: "35ml",
//         stock: 10
//       }
//     ]
//   },
//   {
//     description: ["Our Luminosity Moisturiser for Normal to Oily skin delivers the latest in natural skin science. The formula is designed to deliver light hydration containing Chamomile, Sea Buckthorn, Rosehip, Jojoba and other botanicals to prevent excess oil on the skin. Wheatgrass, Echinacea and Seaweed help eliminate breakouts, while Prickly Pear, Quandong and other powerful anti-oxidants protect the skin from environmental damage. Aloe, Pomegranate and Cucumber ensure hydration; Calendula, Vitamin A, Vitamin E and Acerola Cherry, among the many skin nutrients, provide regenerative qualities which will heal the skin.",
//       "This product is designed for normal to oily and those with extremely oily skin types. It can also be used for skin types experiencing skin problems related to hormonal changes, PMS symptoms, pigmentation and scarring. We have had very positive results in the reduction of mild to moderate acne, pimples, and excess oiliness."
//     ],
//     img: "https://firebasestorage.googleapis.com/v0/b/soleartherapy-b43f7.appspot.com/o/products%2FnormalMoisturiser.png?alt=media&token=4aafdbaa-43e7-4187-97cc-4972671492a7",
//     key: 5,
//     name: "Normal / Oily Moisturiser",
//     ingredients: [
//       "TBC"
//     ],
//     sizes: [
//       {
//         key: 0,
//         price: 30,
//         size: "100ml",
//         stock: 10
//       }
//     ]
//   },
//   {
//     description: ["Combining gentle coconut soap extracts with a unique formula including Wheatgrass, Echinacea and Seaweed to remove and prevent excess oils from the skin, hydrating Cucumber, Aloe Vera and Pomegranate, Bamboo exfoliant to polish and cleanse, botanical extracts to tighten pores, reduce excess oils, pimples, acne and inflammation. Precious regenerators including Rosehip and Avocado, with natural antiseptic and healing Essential Oils.",
//       "This product is designed for normal to extremely oily skin and for younger skin still experiencing skin problems related to hormonal changes, PMS symptoms, pigmentation and scarring.",
//       "For best results use with our Normal / Oily Moisturiser."
//     ],
//     img: "https://firebasestorage.googleapis.com/v0/b/soleartherapy-b43f7.appspot.com/o/products%2FnormalCleanser.png?alt=media&token=703a7dfc-6e83-475c-b31b-3095665e3c38",
//     key: 6,
//     name: "Normal / Oily Daily Cleanser",
//     ingredients: [
//       "TBC"
//     ],
//     sizes: [
//       {
//         key: 0,
//         price: 12,
//         size: "100ml",
//         stock: 10
//       }
//     ]
//   },
//   {
//     description: ["A fusion of botanicals, nutrients and anti-oxidants including Quandong, Prickly Pear and Acerola Cherry, precious moisturising oils with Rosehip and Avocado, hydrating Pomegranate, Aloe and Cucumber, Calendula and other herbs for intense regeneration of cells, collagen and elastin, skin tightening hyaluronic acid and Dragons Blood protection.",
//       "Specially crafted to prevent and reverse environmental damage, the cause of ageing cells.",
//       "Created to reduce redness, inflammation, pigmentation and other problems, maintaining 24 hour moisture and hydration to promote new skin cells.",
//       "For best results use with our Dry / Mature Daily Cleanser."
//     ],
//     img: "https://firebasestorage.googleapis.com/v0/b/soleartherapy-b43f7.appspot.com/o/products%2FdryMoisturiser.png?alt=media&token=bfc3fffa-8732-4432-ae45-63218ad96905",
//     key: 7,
//     name: "Dry / Mature Moisturiser",
//     ingredients: [
//       "TBC"
//     ],
//     sizes: [
//       {
//         key: 0,
//         price: 30,
//         size: "100ml",
//         stock: 10
//       }
//     ]
//   },
//   {
//     description: ["Combining gentle soaps extracted from coconuts, silk polishing Bamboo, Calendula and other healing herbs and regenerative botanicals, hydrating Pomegranate and Aloe, moisturising precious oils including Olive and Avocado, Luminosity Daily Face Cleanser will gently clean, hydrate, moisturise and promote skin regeneration, naturally.",
//       "Promotes regeneration and the growth of collagen and elastin in all skin cells",
//       "For best results use with our Dry / Mature Moisturiser."
//     ],
//     img: "https://firebasestorage.googleapis.com/v0/b/soleartherapy-b43f7.appspot.com/o/products%2FdryCleanser.png?alt=media&token=3ca1caf8-f8ae-4888-b12c-f671b5ddf9b5",
//     key: 8,
//     name: "Dry / Mature Daily Cleanser",
//     ingredients: [
//       "TBC"
//     ],
//     sizes: [
//       {
//         key: 0,
//         price: 12,
//         size: "100ml",
//         stock: 10
//       }
//     ]
//   },
//   {
//     description: ["Liquid Gold Skin Regeneration is packed full of antioxidants and organic botanicals to make your skin feel firmer, smoother, and more youthful with greater elasticity.",
//       "Enriched with herbs, vitamins and restorative oils, Liquid Gold penetrates dry, damaged skin helping delay the effects of ageing, assisting with cell regeneration and promoting the growth of collagen and elastin for glowing, healthy skin.",
//       "In a convenient roll-on bottle, use nightly for the treatment of fine lines, wrinkles and scarring."
//     ],
//     img: "https://firebasestorage.googleapis.com/v0/b/soleartherapy-b43f7.appspot.com/o/products%2FliquidGold.png?alt=media&token=5ee5dac7-b2fa-42bf-869c-360f07251c7e",
//     key: 9,
//     name: "Liquid Gold Skin Regeneration",
//     ingredients: [
//       "TBC"
//     ],
//     sizes: [
//       {
//         key: 0,
//         price: 17.50,
//         size: "100ml",
//         stock: 10
//       }
//     ]
//   },
//   {
//     description: ["To use: Steam inhalation, oil diffuser or vaporiser.",
//       "Our essential oil blends are designed to treat the whole body, offering healing, transformation and fun.",
//       "Our Freedom Blend has been crafted to help you release and relax, feel the sensation of freedom from worry and anxiety. A place to let go and enjoy the sweetness of life.",
//       "Essential Oils are absorbed via the skin and through the nose into the lungs. They are carried around the body to heal, regulate and help our body maintain good health. Their aromas directly affect the limbic system in the brain, which controls memories, emotions and moods. Our olfactory nerves transmit the smell to the brain which triggers a reaction."
//     ],
//     img: "https://firebasestorage.googleapis.com/v0/b/soleartherapy-b43f7.appspot.com/o/products%2Ffreedom.png?alt=media&token=10fdcfa5-6794-49f2-8d52-b0830a9e8018",
//     key: 10,
//     name: "Freedom Blend",
//     ingredients: [
//       "TBC"
//     ],
//     sizes: [
//       {
//         key: 0,
//         price: 35,
//         size: "10ml",
//         stock: 10
//       }
//     ]
//   }
// ]

class FirebaseProvider extends Component {
  state = {
    storeAvailability: null,
    shippingCost: 0,
    products: [],
    detailProduct: null,
    bag: [],
    modalOpen: false,
    modalItem: null
  };

  componentDidMount() {
    app.initializeApp(FirebaseConfig);

    const that = this;

    // app.database().ref('/store').set(
    //   {
    //     open: true,
    //     shippingCost: 0,
    //     products: products
    //   }
    // )

    that.setDeliveryAddressFromLocalStorage();

    app.database().ref('/store').once('value').then(function(snapshot) {
      that.setProducts(snapshot.val());
      that.setState({
        storeAvailability: snapshot.val().open,
        shippingCost: snapshot.val().shippingCost
      })
    });
  }

  setDeliveryAddressFromLocalStorage() {
    if (localStorage.getItem('myDeliveryAddress')) {
      const deliveryAddress = JSON.parse(localStorage.getItem('myDeliveryAddress'));
      this.setState({ deliveryAddress });
    }
  }

  setFromLocalStorage() {
    let localBag = JSON.parse(localStorage.getItem('myBag'));
    let tempProducts = [...this.state.products];

    // Get bagged items
    const baggedProductIndexArray = [];

    for (let i = 0; i < localBag.length; i++) {
      for (let j = 0; j < localBag[i].sizes.length; j++) {
        const sizeKey = localBag[i].sizes[j].key;
        baggedProductIndexArray.push({productKey: localBag[i].key, sizeKey: sizeKey, i: i, j: j});
      }
    }

    // Check still in stock
    const outOfStockArray = [];
    baggedProductIndexArray.forEach((item) => {
      const itemSize = this.getSize(this.getItem(item.productKey), item.sizeKey);
      if (itemSize.stock < localBag[item.i].sizes[item.j].count) {
        outOfStockArray.push({productKey: item.productKey, sizeKey: item.sizeKey});
      }
    })

    // Remove out of stock
    outOfStockArray.forEach((stock) => {
      let tempBag = [...localBag];
      const bagProduct = tempBag.find((item) => item.key === stock.productKey);
      const bagProductSizes = bagProduct.sizes.filter(item => item.key !== stock.sizeKey);
      bagProduct.sizes = bagProductSizes;
      bagProduct.sizes = bagProductSizes;

      const filteredBag = tempBag.filter(item => item.sizes.length > 0);
      localBag = filteredBag;
    })

    // Set product counts
    localBag.forEach((item) => {
      item.sizes.forEach((size) => {
        const tempProduct = tempProducts.find((product) => product.key === item.key);
        const tempProductSize = this.getSize(tempProduct, size.key);
        tempProductSize.inBag = true;
        tempProductSize.count = size.count;
        tempProductSize.total = size.total;
      })
    })


    this.setState({
      bag: localBag,
      products: tempProducts
    }, () => {
      this.setLocalStorage();
    });
  }

  setProducts = (store) => {
    let products = [];
    store.products.forEach(item => {
      const singleItem = {...item};
      singleItem.sizes.forEach(size => {
        size.inBag = false;
        size.count = 0;
        size.total = 0;
      })
      products = [...products, singleItem];
    })
    this.setState(() => {
      return {
        products
      }
    }, () => {
      if (localStorage.getItem('myBag')) {
        this.setFromLocalStorage();
      }
    })
  }

  getBagTotal = () => {
    const { bag } = this.state;
    let total = 0;
    bag.forEach((item) => {
      item.sizes.forEach((size) => {
        total += size.total;
      })
    })
    return total;
  }

  getBagCount = () => {
    const { bag } = this.state;
    let count = 0;
    bag.forEach((item) => {
      item.sizes.forEach((size) => {
        count += size.count;
      })
    })
    return count;
  }

  getItem = (id) => {
    const product = this.state.products.find((item) => item.key === id);
    return product;
  }

  getSize = (product, id) => {
    const size = product.sizes.find((size) => size.key === id);
    return size;
  }

  handleDetail = (id) => {
    const product = this.getItem(id);
    this.setState(() => {
      return {
        detailProduct: product
      }
    });
    return product;
  };

  openModal = (productID, sizeID, quantity) => {
    const n = Number(quantity);
    const product = this.getItem(productID);
    const size = this.getSize(product, sizeID);
    let modalName = product.name;
    const modalPrice = n * size.price;

    if (size.size) {
      modalName = size.size + " " + product.name;
    }

    const modalItem = {
      img: product.img,
      name: modalName,
      price: modalPrice,
      quantity: n
    }

    const that = this;
    setTimeout(function(){
      that.closeModal()},
      4000
    );

    this.setState(() => {
      return {
        modalOpen: true,
        modalItem: modalItem,
      }
    })
  }

  closeModal = () => {
    this.setState(() => {
      return {
        modalOpen: false
      }
    })
  }

  removeFromBag = (productID, sizeID) => {
    let tempProducts = [...this.state.products];
    const index = tempProducts.indexOf(this.getItem(productID));
    const product = tempProducts[index];
    const productSize = this.getSize(product, sizeID);
    productSize.count = 0;
    productSize.inBag = false;
    productSize.total = 0;

    let tempBag = [...this.state.bag];
    const bagProduct = tempBag.find((item) => item.key === productID);
    const bagProductSizes = bagProduct.sizes.filter(item => item.key !== sizeID);
    bagProduct.sizes = bagProductSizes;

    const filteredBag = tempBag.filter(item => item.sizes.length > 0);

    this.setState(
      () => {
        return {
          products: tempProducts,
          bag: filteredBag
        }
      }, () => {
        this.setLocalStorage();
      }
    )
  }

  emptyBag = () => {
    let tempBag = [...this.state.bag];
    tempBag.forEach((item) => {
      item.sizes.forEach((size) => {
        this.removeFromBag(item.key, size.key);
      })
    })
  }

  setLocalStorage() {
    localStorage.setItem('myBag', JSON.stringify(this.state.bag));
  }

  setLocalStorageDeliveryAddress() {
    localStorage.setItem('myDeliveryAddress', JSON.stringify(this.state.deliveryAddress));
  }

  productInBag(itemId) {
    const product = this.getItem(itemId);
    if (product.sizes.find(e => e.inBag === true)) {
      return true;
    }
    return false;
  }

  editBagCount = (itemId, sizeId, n) => {
    const quantity = Number(n)
    let tempBag = [...this.state.bag];
    const product = tempBag.find((item) => item.key === itemId);
    const sizeIndex = product.sizes.indexOf(this.getSize(product, sizeId));
    const productItem = product.sizes[sizeIndex];

    // If number set to zero remove from stock
    if (productItem.count + quantity <= 0) {
      return this.removeFromBag(itemId, sizeId);
    }

    // If number set is greater than stock return null
    if (productItem.count + quantity > productItem.stock) {
      return null;
    }

    productItem.count += quantity;
    productItem.total += productItem.price * quantity;
    this.setState(
      () => {
        return {
          bag: tempBag
        }
      }, () => {
        this.setLocalStorage();
      }
    )
  }

  addToBag = (itemId, sizeId, n) => {
    const quantity = Number(n)
    let tempProducts = [...this.state.products];
    const index = tempProducts.indexOf(this.getItem(itemId));
    const product = tempProducts[index];
    const sizeIndex = product.sizes.indexOf(this.getSize(product, sizeId));
    const productItem = product.sizes[sizeIndex];
    productItem.count += quantity;
    productItem.total += productItem.price * quantity;

    if (!this.productInBag(itemId)) {

      productItem.inBag = true;
      const baggedSizes = product.sizes.filter(size => size.inBag === true);
      const baggedProduct = {};
      Object.assign(baggedProduct, product);
      baggedProduct.sizes = baggedSizes;

      this.setState(
        () => {
          return {
            products: tempProducts,
            bag: [...this.state.bag, baggedProduct]
          }
        }, () => {
          this.setLocalStorage();
        }
      )
    // If product in Bag
    } else {
      let tempBag = [...this.state.bag];
      const baggedProduct = tempBag.find((item) => item.key === itemId);
      // If size is not in bag
      if (!baggedProduct.sizes.some((item) => item.key === sizeId)) {
        productItem.inBag = true;
        baggedProduct.sizes.push(productItem);
      }
      this.setState(
        () => {
          return {
            products: tempProducts
          }
        }, () => {
          this.setLocalStorage();
        }
      )
    }
  }

  setDeliveryAddress = (deliveryAddress) => {
    this.setState(
      () => {
        return {
          deliveryAddress
        }
      }, () => {
        this.setLocalStorageDeliveryAddress();
      }
    )
  }

  render() {
    return (
      <FirebaseContext.Provider value={{
        ...this.state,
        handleDetail: this.handleDetail,
        addToBag: this.addToBag,
        removeFromBag: this.removeFromBag,
        getBagTotal: this.getBagTotal,
        getItem: this.getItem,
        getSize: this.getSize,
        getBagCount: this.getBagCount,
        openModal: this.openModal,
        closeModal: this.closeModal,
        emptyBag: this.emptyBag,
        editBagCount: this.editBagCount,
        setDeliveryAddress: this.setDeliveryAddress
      }}>
        {this.props.children}
      </FirebaseContext.Provider>
    )
  }
}

const FirebaseConsumer = FirebaseContext.Consumer;

const withFirebase = Component => props => (
  <FirebaseConsumer>
    {firebase => <Component {...props} firebase={firebase} />}
  </FirebaseConsumer>
);

export { FirebaseProvider, FirebaseConsumer, withFirebase };