import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import ScrollToTop from 'react-router-scroll-top';
import { FirebaseConsumer } from '../../config/firebase';
import { StripeProvider, Elements } from 'react-stripe-elements';
import * as ROUTES from '../../constants/routes.js';
import Header from '../header/header.js';
import Home from '../home/home.js';
import Services from '../services/services.js';
import Testimonials from '../testimonials/testimonials.js';
import Shop from '../shop/shop.js';
import AboutReflexology from '../about-reflexology/about-reflexology.js';
import WhatToExpect from '../what-to-expect/what-to-expect.js';
import FAQ from '../faq/faq.js';
import Contact from '../contact/contact.js';
import ShoppingBag from '../shopping-bag/shopping-bag.js';
import Checkout from '../checkout/checkout.js';
import Footer from '../footer/footer.js';
import Product from '../product/product.js';
import Admin from '../admin/admin.js';
import Success from '../success/success.js';
import PrivacyPolicy from '../privacy-policy/privacy-policy.js';
import { ItemAddedModal } from '../../components/reuseable-components/reuseable-components.js';

const navBarLinks = [
  {
    to: ROUTES.HOME,
    title: "Home",
    key: 0
  },
  {
    title: "About",
    key: 1,
    dropdown: [
      {
        title: 'About Reflexology',
        to: ROUTES.ABOUT_REFLEXOLOGY,
        key: 0
      },
      {
        title: 'What to Expect',
        to: ROUTES.WHAT_TO_EXPECT,
        key: 1
      },
      {
        title: 'FAQ',
        to: ROUTES.FAQ,
        key: 2
      }
    ]
  },
  {
    to: ROUTES.SERVICES,
    title: "Services",
    key: 2
  },
  {
    to: ROUTES.TESTIMONIALS,
    title: "Testimonials",
    key: 3
  },
  {
    to: ROUTES.SHOP,
    title: "Shop",
    key: 4
  }
]


const routes = [
  {
    path: ROUTES.HOME,
    exact: true,
    content: () => <Home testimonials={testimonials} />
  },
  {
    path: ROUTES.SERVICES,
    content: () => <Services />
  },
  {
    path: ROUTES.TESTIMONIALS,
    content: () => <Testimonials testimonials={testimonials} />
  },
  {
    path: ROUTES.SHOP,
    exact: true,
    content: () => <Shop />
  },
  {
    path: "/shop/:productId",
    content: () => <Product />
  },
  {
    path: ROUTES.ABOUT_REFLEXOLOGY,
    content: () => <AboutReflexology />
  },
  {
    path: ROUTES.WHAT_TO_EXPECT,
    content: () => <WhatToExpect />
  },
  {
    path: ROUTES.FAQ,
    content: () => <FAQ />
  },
  {
    path: ROUTES.CONTACT,
    content: () => <Contact />
  },
  {
    path: ROUTES.SHOPPING_BAG,
    content: () => <ShoppingBag />
  },
  {
    path: ROUTES.ADMIN,
    content: () => <Admin />
  },
  {
    path: ROUTES.CHECKOUT,
    content: () => (
      <Elements>
        <Checkout />
      </Elements>
    )
  },
  {
    path: ROUTES.PRIVACY_POLICY,
    content: () => <PrivacyPolicy />
  },
  {
    path: ROUTES.SUCCESS,
    content: () => <Success />
  }
]

const testimonials = [
  {
    name: 'Simon Isaac',
    from: 'Southend-on-Sea',
    gender: 'male',
    quote: 'Having a course of treatment with Sue at Soleartherapy has made an amazing difference to my life. I’ve always preferred holistic therapies and together with Sue, we’ve focused on addressing specific health issues I have had for many years. I also continue with treatment as a preventative measure, with the philosophy that prevention is better than cure.'
  },
  {
    name: 'Lucy Jenkins',
    from: 'Benfleet',
    gender: 'female',
    quote: 'I have been having reflexology with Soleartherapy for almost two years and cannot recommend Sue enough. She takes the time to understand your needs and I can honestly say has made a positive difference to my life. I work in a high pressured job and used to suffer frequent stress related headaches this is now no longer an issue thanks to Reflexology.'
  },
  {
    name: 'Annie Roberts',
    from: 'Benfleet',
    gender: 'female',
    quote: 'Sue was recommended to me by a mutual friend about 15 months ago and I am pleased they did. I had started to suffer from menopausal symptoms and with Sue’s help I have kept them under control and reduced the impact on my life. I have seen positive results in my mental and physical health from her work. Sue still has the ability to surprise me when she will comment on a different health aspect, that I have not shared with her, when manipulating my feet. The added bonus is that she comes to my home so I can continue to relax after our session has finished. I would recommend Sue to anyone who wants to address symptoms of the menopause or generally feel more relaxed, calm and in control.'
  },
  {
    name: 'Gill Underhill',
    from: 'Benfleet',
    gender: 'female',
    quote: 'I would like to highly recommend Sue and her treatments, she has been able to bring back some balance in my body after years of pain due to scoliosis and disc problems. Sue is able to pinpoint specific problem areas and work on them to help me gain relief. I feel totally relaxed and stress free after each treatment which in turn helps to manage the pain.'
  },
  {
    name: 'Nicola Jones',
    from: 'Hadleigh',
    gender: 'female',
    quote: 'I always leave a reflexology treatment with Sue feeling calm and relaxed. Sue is welcoming and friendly and the atmosphere is chilled and laid back. She talks through how you are feeling and discusses any concerns. A very enjoyable treatment, would highly recommend.'
  },
  {
    name: 'Lucy Markham',
    from: 'Rayleigh',
    gender: 'female',
    quote: 'Sue is amazing - she was always on time and reflexology sessions with her were always exceptionally relaxing. I never wanted them to end. I really saw progress over the time that she was treating me. I would highly recommend Sue.'
  },
  {
    name: 'Jill Isles',
    from: 'Chalkwell',
    gender: 'female',
    quote: 'Myself and my husband have found our sessions with Sue very rewarding and we both look forward to them. She is very relaxed and friendly and my husband who is a very old sceptic dentist is now sold on Reflexology.'
  }
]

function App() {
  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
      <Router>
        <ScrollToTop>
          <FirebaseConsumer>
            {value => {
              if (value.modalOpen) {
                return (
                  <ItemAddedModal
                    img={value.modalItem.img}
                    name={value.modalItem.name}
                    price={value.modalItem.price}
                    quantity={value.modalItem.quantity}
                    to={ROUTES.SHOPPING_BAG}
                    onClick={() => value.closeModal()}
                  />
                )
              }
            }}
          </FirebaseConsumer>
          <Header links={navBarLinks} />
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={<route.content />}
              />
            ))}
          </Switch>
          <Footer />
        </ScrollToTop>
      </Router>
    </StripeProvider>
  );
}

export default App;
