import React from 'react';
import { Banner } from '../reuseable-components/reuseable-components';
import { UseWindowSize } from '../hooks/reuseable-hooks';
import { servicesBanner, sunsetLeft, servicesBannerLoad } from '../../assets/images';
import { CONTACT } from '../../constants/routes';
import {
  Link
} from "react-router-dom";
import './services.css';

function ServicesPanel(props) {
  return (
    <div
      className="services-panel"
      style={{backgroundImage: `url(${sunsetLeft})`}}
    >
      <div className="text-container border-box">
        {props.children}
      </div>
    </div>
  )
}

function ServicesPanels() {
  const windowSize = UseWindowSize();
  if (windowSize.width >= 1024) {
    // Laptop View
    return (
      <div className="row services-panels">
        <ServicesPanel>
          <div className="column services-panel-child">
            <h3 className="service-name">Reflexology</h3>
            <hr className="thin-hr" />
            <h3>
              £45<br />
              <span>First Treatment*</span>
            </h3>
            <h3>
              £35<br />
              <span>For all follow up treatments</span>
            </h3>
          </div>
          </ServicesPanel>
          <div className="most-popular">
          <div className="most-popular-text-container">
            <h3 className="primary-brown">Most Popular</h3>
          </div>
          <ServicesPanel>
            <div className="column services-panel-child">
              <h3 className="service-name">Reflexology + Natural Lift Facial Massage</h3>
              <hr className="thin-hr" />
              <h3>
                £60<br />
                <span>Per treatment</span>
              </h3>
            </div>
          </ServicesPanel>
        </div>
        <ServicesPanel>
          <div className="column services-panel-child">
            <h3 className="service-name">Natural Lift Facial Massage</h3>
            <hr className="thin-hr" />
            <h3>
              £30<br />
              <span>Per treatment</span>
            </h3>
            <h3>
              £150<br />
              <span>For a course of 6</span>
            </h3>
          </div>
        </ServicesPanel>
      </div>
    );
  }
  return (
    <div className="column">
      <div className="most-popular">
        <div className="most-popular-text-container">
          <h3 className="primary-brown">Most Popular</h3>
        </div>
        <ServicesPanel>
          <div className="column services-panel-child">
            <h3>Reflexology + Natural Lift Facial Massage</h3>
            <hr />
            <h3>
              £60<br />
              <span>Per treatment</span>
            </h3>
          </div>
        </ServicesPanel>
      </div>
      <ServicesPanel>
        <div className="column services-panel-child">
          <h3>Reflexology</h3>
          <hr />
          <h3>
            £45<br />
            <span>First Treatment*</span>
          </h3>
          <h3>
            £35<br />
            <span>For all follow up treatments</span>
          </h3>
        </div>
      </ServicesPanel>
      <ServicesPanel>
        <div className="column services-panel-child">
          <h3>Natural Lift Facial Massage</h3>
          <hr />
          <h3>
            £30<br />
            <span>Per treatment</span>
          </h3>
          <h3>
            £150<br />
            <span>For a course of 6</span>
          </h3>
        </div>
      </ServicesPanel>
    </div>
  )
}

function Services() {
  return (
    <div className="services-wrapper column">
      <Banner title="Services" src={servicesBanner} preview={servicesBannerLoad} />
      <div className="content">
        <ServicesPanels />
        <div className="column cta-container">
          <Link to={CONTACT} >
            <button className="primary-button">Book Now</button>
          </Link>
          <p>* The first reflexology treatment includes an in-depth face-to-face consultation. A brief telephone consultation must also take place prior to this. To arrange this, please fill in our contact form.</p>
        </div>
      </div>
      <div className="service-policies border-box">
        <h2 className="primary-brown">Cancellation Policy</h2>
        <p>
          All cancellations must be confirmed at least 24 hours before a treatment is due to take place.<br />
          <b className="primary-brown">Please Note:</b> Clients remain liable for payment if cancellations are not made within this timeframe.
        </p>
      </div>
    </div>
  )
}

export { ServicesPanels };
export default Services;