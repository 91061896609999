import React from 'react';
import { CheckCircle } from 'react-feather';
import { FirebaseConsumer } from '../../config/firebase';
import {
  Link
} from "react-router-dom";
import './success.css';
import { SHOP } from '../../constants/routes';

function Success() {
  return (
    <FirebaseConsumer>
      {value => {
        value.emptyBag();

        return (
          <div className="success-container">
            <div className="content">
              <CheckCircle strokeWidth={1} color="#6F1D00" />
              <div className="column">
                <h1 className="primary-brown">Order Confirmed</h1>
                <p>
                  Your payment has been received and your order is now confirmed. An email receipt will be sent to you shortly.
                </p>
                <Link to={SHOP} className="primary-button button">
                  Back to shop
                </Link>
              </div>
            </div>
          </div>
        )
      }}
    </FirebaseConsumer>
  )
}

export default Success;