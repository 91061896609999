import React, { useState } from 'react';
import { FirebaseConsumer } from '../../config/firebase';
import { UseWindowSize } from '../hooks/reuseable-hooks.js';
import {
  Link
} from "react-router-dom";
import { X, MinusCircle, PlusCircle } from 'react-feather';
import { CHECKOUT } from '../../constants/routes.js';
import './shopping-bag.css';

function BaggedProduct(props) {
  const [active, setActive] = useState(false);
  const windowSize = UseWindowSize();
  const { size, value, item } = props;

  return (
    <div
      className="bagged-product"
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <hr className="thin-hr" />
      <div className="container">
        <div className="product-img">
          <Link
            to={`shop/${item.key}`} onClick={() => value.handleDetail(item.key)}
            className="bag-item-image"
            style={{backgroundImage: `url(${item.img})`}}
          >
          </Link>
          {windowSize.width < 1024 &&
            <X
              className="x"
              onClick={() => value.removeFromBag(item.key, size.key)}
            />
          }
        </div>

        <div className="column product-details">
          <div className="product-name-container row">
            <h3>{size.size} {item.name}</h3>
              {windowSize.width >= 1024 && active &&
                <X
                  className="x"
                  onClick={() => value.removeFromBag(item.key, size.key)}
                />
              }
          </div>
          <div className="row quantity">
            <MinusCircle
              onClick={() => value.editBagCount(item.key, size.key, -1)}
              color={active || (windowSize.width < 1024) ? '#F56600' : '#707070'}
              className="clickable"
            />
            <p>
              {size.count} x
            </p>
            <PlusCircle
              onClick={() => value.editBagCount(item.key, size.key, 1)}
              color={active || (windowSize.width < 1024) ? '#F56600' : '#707070'}
              className="clickable"
            />
          </div>
          <p className="product-cost">
            £{size.price.toFixed(2)}
          </p>
          <p>
            Total: £{size.total.toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  )

}

function BagContents(props) {
  const value = props.value;
  const bag = value.bag;

  if (bag.length <= 0) {
    return (
      <div>
        <p>Shopping Bag is empty.</p>
      </div>
    )
  }

  return (
    <div className="bag-contents">
      <h3>{value.getBagCount()} item{value.getBagCount() > 1 && <span>s</span>}</h3>
      {
        bag.map((item) =>
          item.sizes.map((size) => (
            <BaggedProduct value={value} size={size} item={item} key={[item.key, size.key]} />
          )
        ))
      }
    </div>
  )
}

function ShoppingBag() {
  return (
    <FirebaseConsumer>
      {value => {
        const merchandiseTotal = value.getBagTotal();
        const shippingCost = value.shippingCost;

        return (
          <div className="shopping-bag">
            <div className="content">
              <div className="column panel">
                <h2 className="primary-brown">Your Bag</h2>
                <hr className="thick-hr" />
                <BagContents value={value} />
              </div>
              <div className="column order-summary panel shadow">
                <h2 className="primary-brown">Order Summary</h2>
                <hr className="thick-hr" />
                <div className="row space-between">
                  <p>Merchandise:</p>
                  <p>£{merchandiseTotal.toFixed(2)}</p>
                </div>
                <div className="row space-between">
                  <p>Estimated Shipping:</p>
                  {(shippingCost === 0) ? <h3>FREE</h3> : <p>£{shippingCost.toFixed(2)}</p>}
                </div>
                <hr className="thin-hr" />
                <div className="row space-between">
                  <h3>Order Total:</h3>
                  <p>£{(merchandiseTotal + value.shippingCost).toFixed(2)}</p>
                </div>
                <Link to={CHECKOUT}>
                  <button className="primary-button" disabled={value.bag.length <= 0}>Checkout</button>
                </Link>
              </div>
            </div>
          </div>
            
        )
        
      }}
    </FirebaseConsumer>
  )
}

export default ShoppingBag;