import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { FirebaseConsumer } from '../../config/firebase';
import { UseWindowSize } from '../hooks/reuseable-hooks.js';
import { PaymentProcessing } from '../reuseable-components/reuseable-components.js';
import StyledInput from '../reuseable-components/styled-input';
import {
  Redirect
} from "react-router-dom";
import {
  injectStripe
} from 'react-stripe-elements';
import { Edit } from 'react-feather';
import Axios from 'axios';
import './checkout.css';
import { SHOPPING_BAG } from '../../constants/routes';

function DeliveryAddressForm(props) {
  const windowSize = UseWindowSize();
  const {value, handleSubmit} = props;

  const [ firstName, setFirstName ] = useState("");
  const [ lastName, setLastName ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ postCode, setPostCode ] = useState("");
  const [ addressLine1, setAddressLine1 ] = useState("");
  const [ addressLine2, setAddressLine2 ] = useState("");
  const [ town, setTown ] = useState("");
  const [ county, setCounty ] = useState("");

  const [ addressSet, setAddressSet ] = useState(false);
  const [ hover, setHover ] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const submission = {
      firstName,
      lastName,
      email,
      postCode,
      addressLine1,
      addressLine2,
      town,
      county
    }

    if (firstName && lastName && email && postCode && addressLine1 && town && county) {
      value.setDeliveryAddress(submission);
    }
  }

  useEffect(() => {
    if (value.deliveryAddress) {
      const address = value.deliveryAddress;
      setFirstName(address.firstName);
      setLastName(address.lastName);
      setEmail(address.email);
      setPostCode(address.postCode);
      setAddressLine1(address.addressLine1);
      setAddressLine2(address.addressLine2);
      setTown(address.town);
      setCounty(address.county);

      setAddressSet(true);
      handleSubmit(value.deliveryAddress);
    }
  }, [value.deliveryAddress, handleSubmit]);

  if (addressSet) {
    return (
      <div
        className="delivery-address-form"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <p>
          {firstName} {lastName}<br />
          {addressLine1}<br />
          {town}<br />
          {county}<br />
          {postCode}<br />
          <br />
          {email}
        </p>
        {(windowSize.width < 1024 || hover ) &&
          <Edit className="edit" onClick={() => setAddressSet(false)} />
        }
      </div>
    )
  }

  return (
    <form className="column delivery-address-form" onSubmit={handleFormSubmit}>
      <div className={(windowSize.width >= 1024 ? "row-name-inputs" : "name-inputs")}>
        <StyledInput
          id='firstName'
          label='First Name'
          onChange={e => setFirstName(e.target.value)}
          value={firstName}
          type="text"
          required={true}
          style={{width: '45%'}}
        />
        <StyledInput
          id='lastName'
          label='Last Name'
          onChange={e => setLastName(e.target.value)}
          value={lastName}
          type="text"
          required={true}
          style={{width: '45%'}}
        />
      </div>
      <StyledInput
        id='email'
        label='Email Address'
        onChange={e => setEmail(e.target.value)}
        value={email}
        type="email"
        required={true}
      />
      <StyledInput
        id='addressLine1'
        label='Address Line 1'
        onChange={e => setAddressLine1(e.target.value)}
        value={addressLine1}
        type="text"
        required={true}
      />
      <StyledInput
        id='addressLine2'
        label='Address Line 2'
        onChange={e => setAddressLine2(e.target.value)}
        value={addressLine2}
        type="text"
        required={false}
      />
      <StyledInput
        id='town'
        label='Town / City'
        onChange={e => setTown(e.target.value)}
        value={town}
        type="text"
        required={true}
      />
      <StyledInput
        id='county'
        label='County / Province'
        onChange={e => setCounty(e.target.value)}
        value={county}
        type="text"
        required={true}
      />
      <StyledInput
        id='postCode'
        label='Post Code'
        onChange={e => setPostCode(e.target.value)}
        value={postCode}
        type="text"
        required={true}
      />
      <input
        type="submit"
        value="Submit"
        className="button primary-button"
      />
    </form>
  )

}

function Checkout(props) {
  const [ address, setAddress ] = useState({});
  const [ accessPaymentForm, setAccessPaymentForm ] = useState(false);
  const [ paymentProcessing, setPaymentProcessing ] = useState(false);
  const [ paymentError, setPaymentError ] = useState('');

  const handleDeliveryAddressSubmit = (address) => {
    setAddress(address);
    setAccessPaymentForm(true);
  }

  const handleCheckout = async (e, value) => {
    e.preventDefault();
    setPaymentProcessing(true);

    // For checkout
    const line_items = [];
    // For order reciept
    const order = [];
    // For database stock check
    const order_keys = [];

    value.bag.forEach((item) => {
      order.push({
        key: item.key,
        name: item.name,
        sizes: item.sizes
      })
      item.sizes.forEach((size) => {
        line_items.push({
          name: item.name,
          images: [item.img],
          amount: size.price * 100,
          currency: 'gbp',
          quantity: size.count
        })

        order_keys.push({
          productKey: item.key,
          sizeKey: size.key,
          quantity: size.count
        })
      })
    })
    
    try {
      const checkout = await Axios.post('https://europe-west1-soleartherapy-b43f7.cloudfunctions.net/checkout', {
        line_items,
        customer_email: address.email,
        order_keys
      });

      const id = checkout.data.session.id;

      const order_data = {
        delivery_address: address,
        order,
        payment_status: 'pending'
      }

      await firebase
        .database()
        .ref('orders')
        .child(id)
        .set({
          order_data
        });

      props.stripe.redirectToCheckout({
        sessionId: id
      })
    } catch (err) {
      setPaymentError(err.message);
      setPaymentProcessing(false);
    }
  }

  return (
    <FirebaseConsumer>
      {value => {
        if (value.getBagTotal() <= 0) {
          return (
            <Redirect to={SHOPPING_BAG} />
          )
        }

        const total = value.getBagTotal() + value.shippingCost;

        return (
          <div className="checkout">
            <div className="content">
              { paymentProcessing && <PaymentProcessing /> }
              <div className="checkout-forms column">
                <div
                  className="shadow delivery-address"
                >
                  <h2 className="primary-brown">Delivery Address</h2>
                  <p className="note">
                    If you live outside the United Kingdom, please visit the Rare Earth Oils website to find your nearest stockist.
                  </p>
                  <DeliveryAddressForm handleSubmit={handleDeliveryAddressSubmit} value={value} />
                </div>
                <div
                  className="shadow payment-details"
                  style={{opacity: accessPaymentForm ? 1 : 0.5}}
                >
                  <h2 className="primary-brown">Proceed to Checkout</h2>
                  <p className="note">
                    If you are confident with your delivery details,
                    complete the order by pressing the button below where you will be redirected to a secure <b>stripe</b> checkout.
                  </p>
                  <button className="primary-button" onClick={(e) => handleCheckout(e, value)}>
                    Pay by card
                  </button>
                </div>
              </div>
            <div className="order-summary">
              <h2 className="primary-brown">Bag total</h2>
              <hr className="thick-hr" />
        
              <div className="order-products">
                {value.bag.map((item) => (
                  item.sizes.map((size) => (
                    <div key={[item.key, size.key]} className="column order-product">
                      <div className="row">
                        <div
                          to={`shop/${item.key}`} onClick={() => value.handleDetail(item.key)}
                          className="order-product-img"
                          style={{backgroundImage: `url(${item.img})`}}
                        >
                        </div>
                        <div className="row space-between order-product-details">
                          <div className="column">
                            <p>{size.count} x {item.name}</p>
                            <p>{size.size}</p>
                          </div>
                          <p>£{size.total.toFixed(2)}</p>
                        </div>
                      </div>
                      <hr className="thin-hr" />
                    </div>
                  ))
                ))}
                <div className="row space-between costs">
                  <p>Order value</p>
                  <p>£{value.getBagTotal().toFixed(2)}</p>
                </div>
                <div className="row space-between costs">
                  <p>Delivery</p>
                  <p>£{value.shippingCost.toFixed(2)}</p>
                </div>
                <hr className="thin-hr" />
                <div className="row space-between">
                  <h3>Total</h3>
                  <p>£{total.toFixed(2)}</p>
                </div>
              </div>
              { paymentError && 
                <p className="error-message">Error: {paymentError}</p>
              }
            </div>
          </div>
        </div>
      )
    }}
  </FirebaseConsumer>
  )
}

export default injectStripe(Checkout);