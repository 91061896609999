import React from 'react';

const getViewBox = name => {
  switch (name) {
    case "quote":
      return "0 0 46.79 38.95";
    case "closed-quote":
      return "0 0 46.79 38.95";
    case "mail":
      return "0 0 96.872 77.509";
    case "location":
      return "0 0 52.72 85.77";
    case "man":
      return "0 0 66.83 83.7";
    case "woman":
      return "0 0 59.82 77.83";
    default:
      return "0 0 32 32";
  }
};

const getPath = (name, props) => {
  switch (name) {
    case "quote":
      return (
        <g>
          <path {...props} d="M13,38.42c4.81-.32,8.69-4.07,8.4-8.94a8.51,8.51,0,0,0-9-7.89,8.4,8.4,0,0,0-2.75.66A2.85,2.85,0,0,1,5.74,20.2C3.82,10.65,17.65,1.8,17.65,1.8V0C8.79,1.37,0,12,0,21.29,0,27,.89,32.51,4.93,35.91,4.93,35.91,7.55,38.78,13,38.42Z"/>
          <path {...props} d="M38.37,38.92c4.81-.31,8.7-4.06,8.41-8.94a8.53,8.53,0,0,0-9-7.89,8.66,8.66,0,0,0-2.75.66,2.85,2.85,0,0,1-3.95-2.05C29.17,11.16,43,2.3,43,2.3V.5c-8.86,1.37-17.65,12-17.65,21.29,0,5.69.89,11.22,4.93,14.62C30.28,36.41,32.9,39.28,38.37,38.92Z"/>
        </g>
      );
    case "closed-quote":
      return (
        <g>
          <path {...props} d="M33.77.53c-4.81.32-8.69,4.07-8.4,9a8.5,8.5,0,0,0,9,7.88,8.39,8.39,0,0,0,2.74-.65,2.85,2.85,0,0,1,3.95,2.05C43,28.3,29.14,37.15,29.14,37.15V39c8.86-1.37,17.65-12,17.65-21.29,0-5.69-.89-11.21-4.93-14.61C41.86,3.05,39.24.17,33.77.53Z"/>
          <path {...props} d="M8.42,0C3.61.35-.27,4.1,0,9a8.51,8.51,0,0,0,9,7.89,8.15,8.15,0,0,0,2.75-.66,2.85,2.85,0,0,1,3.95,2.05c1.92,9.55-11.91,18.4-11.91,18.4v1.8c8.86-1.37,17.65-12,17.65-21.29,0-5.69-.89-11.22-4.93-14.62C16.51,2.54,13.89-.33,8.42,0Z"/>
        </g>
      );
    case "mail":
      return (
        <g transform="translate(-43.177 -99.315)">
          <path {...props} d="M82.015,148.918,43.72,128.595l48-28.9,47.993,28.9-38.288,20.319" transform="translate(-0.041)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
          <path {...props} d="M43.177,182.73" transform="translate(0 -6.281)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
          <line {...props} x1="95.995" transform="translate(43.679 176.449)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
          <path {...props} d="M95.65,151.45" transform="translate(-3.969 -3.915)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
          <path {...props} d="M95.65,151.45" transform="translate(-3.969 -3.915)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
          <path {...props} d="M106.149,152.942" transform="translate(-4.763 -4.028)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
          <path {...props} d="M43.72,130.96v47.854l48-28.914,47.993,28.914V130.96" transform="translate(-0.041 -2.365)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
          <path {...props} d="M85.148,152.945" transform="translate(-3.174 -4.028)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
          <path {...props} data-name="Path 241" d="M95.65,151.45" transform="translate(-3.969 -3.915)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
          <line {...props} x2="78.355" transform="translate(52.499 128.595)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
          <path {...props} d="M108.965,151.45" transform="translate(-4.976 -3.915)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
        </g>
      );
      case "location":
        return (
          <path {...props} d="M204.07,99.69a25.986,25.986,0,0,0-23.28,37.52c.39.68.81,1.4,1.22,2.16a.467.467,0,0,1,.05.1c8.54,15.47,22.01,45.24,22.01,45.24s13.47-29.77,22.01-45.24c.02-.03.04-.07.06-.1.4-.72.79-1.4,1.17-2.06.02-.03.03-.07.05-.1a25.986,25.986,0,0,0-23.29-37.52Zm0,37.52a11.285,11.285,0,1,1,11.29-11.28A11.28,11.28,0,0,1,204.07,137.21Z" transform="translate(-177.715 -99.315)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75"/>
        )
      case "man":
        return (
          <g>
            <path {...props} d="M66.29,69.53A48.7,48.7,0,0,1,.54,71.28s1.7-9.59,7.7-11.47S25.7,57.37,24.57,43.48a26.68,26.68,0,0,1-5.25-11.63s-2.44,3.38-3-10.14c0,0-.18-1,1.69-.94C18,20.77,14,.59,32.46.5s15.95,17,16.14,20.37c0,0,1.78-1,1.78.56s.47,9.2-3,10.14c0,0-1,6.85-5.16,11.54,0,0-4.22,14.26,12.86,15.39C55.08,58.5,64.84,60.19,66.29,69.53Z"/>
          </g>
        )
      case "woman":
        return (
          <g>
            <path {...props} d="M59.28,64.83A43.21,43.21,0,0,1,.51,66.71s.18-7.52,6.06-9.91,16.22-3.18,15.59-12.08H14s2.22-4.61,1-9.23S4.33-.28,30,.51c25.68-.79,16.32,30.37,15.06,35s1,9.23,1,9.23H37.87c-.64,8.9,9.7,9.7,15.58,12.08C57.46,58.43,58.83,62.45,59.28,64.83Z"/>
          </g>
        )
  
    default:
      return <path />;
  }
};

const SVGIcon = ({
  name="",
  style = {},
  stroke= '#000',
  fill = 'none',
  width = '100%',
  className = '',
  viewBox = "",
}) => {
  return(
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox || getViewBox(name)}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      { getPath(name, { fill, stroke }) }
    </svg>
  )
  
}

export {
  SVGIcon
};