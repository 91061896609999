import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import ProgressiveImage from "react-progressive-image-loading";
import './reuseable-components.css';
import {
  Link
} from "react-router-dom";

function DashedTitle(props) {
  const style = {
    ...props.style,
    color: props.color
  }

  return (
    <div className="dashed-title" style={style}>
      <div className="dash">
        <hr align='left' size={1} />
      </div>
      <h3>{props.title}</h3>
      <div className="dash">
        <hr align='right' size={1} />
      </div>
    </div>
  )
}

function PaymentProcessing() {
  return (
    <div className="payment-processing">
      <div className="payment-processing-content border-box">
        <ReactLoading type={'spinningBubbles'} color={'#6F1D00'} height={75} width={75} />
        <h3>Proceeding to checkout</h3>
        <p>
          This process may take a few seconds, please be patient.
        </p>
      </div>
    </div>
  )
}

function Banner(props) {
  return (
    <ProgressiveImage 
      preview={props.preview}
      src={props.src}
      transitionTime={500}
      transitionFunction="ease"
      render={
        (src, style) => <div
          className="banner border-box"
          style={{backgroundImage: `url(${src})`}}
        >
          <DashedTitle title={props.title} />
        </div>
      }
    />
  )
}

function SecondaryButton(props) {
  const [hover, setHover] = useState(false);
  return (
    <Link
      to={props.to}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={'secondary-button ' + (hover ? 'primary-button' : null)}
      onClick={() => props.onClick()}
    >
      {props.text} {hover}
    </Link>
  )
}

function ItemAddedModal(props) {
  const { img, name, price, to, quantity} = props;
  return (
    <div className="modal-container shadow">
      <div className="product-image-container">
        <div
          className="product-image"
          style={{backgroundImage: `url(${img})`}}
        >
        </div>
      </div>
      <div className="product-info-container">
        <h3>Added to bag</h3>
        <hr />
        <div className="product-info">
          <div className="product-title">
            {quantity > 1 &&
              <p>{quantity} x</p>
            }
            <p>{name}</p>
          </div>
          <p>£{price.toFixed(2)}</p>
        </div>
        <Link to={to}>
          <button onClick={props.onClick}>
            Checkout
          </button>
        </Link>
      </div>
    </div>
  )
}

export { DashedTitle, Banner, ItemAddedModal, SecondaryButton, PaymentProcessing }