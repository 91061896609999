import React from 'react';
import { Banner, DashedTitle } from '../reuseable-components/reuseable-components';
import ProgressiveImage from "react-progressive-image-loading";
import { aboutBanner, aboutBannerLoad, whatIs, benefits, treats, egypt, tapestry, whatIsLoad } from '../../assets/images';
import './about-reflexology.css';

function AboutReflexology() {
  return (
    <div className="about-reflexology column">
      <Banner title="About Reflexology" src={aboutBanner} preview={aboutBannerLoad} />
      <div className="content column">
        <div className="panel">
          <div className="column">
            <DashedTitle title="What is reflexology?" style={{paddingBottom: '12px'}} />
            <div className="what-is">
              <p>
                Reflexology is a non-invasive, holistic therapy effective in promoting deep relaxation and wellbeing. Reflexology facilitates the reduction of stress in people’s lives and so helps to build resilience and optimise good health. It is a touch therapy based on the theory that different points on the feet, lower leg, hands, face or ears correspond with different areas of the body. Reflexologists work these points and areas to get the best tailored results for each individual client.<br />
                <br />
                To find out more, visit <a href="https://www.aor.org.uk/what-is-reflexology/" target="_blank">The AoR website</a>.
              </p>
              <ProgressiveImage
                preview={whatIsLoad}
                src={whatIs}
                render={(src, style) => <img src={src} alt="Feet being massaged" />}
              />
            </div>
          </div>
        </div>

        <div className="benefit-panels">
          <div className="panel shadow column border-box">
            
            <img src={treats} alt="Reflexology treatment" />
            <h3 className="primary-brown">What can reflexology help treat and manage</h3>
            <ul>
              <li>Stress</li>
              <li>Anxiety/Depression</li>
              <li>Infertility and Pregnancy</li>
              <li>Menopause</li>
              <li>Headaches and Migraines</li>
              <li>Sleep Disorders</li>
              <li>Arthritis</li>
              <li>Hormonal imbalances</li>
              <li>Poor digestion</li>
              <li>Irritable bowel syndrome (IBS)</li>
              <li>Respiratory problems (e.g. Asthma)</li>
              <li>Bladder issues</li>
            </ul>
          </div>

          <div className="panel shadow column border-box">
            <img src={benefits} alt="Celebrating with a sun rise" />
            <h3 className="primary-brown">Benefits of reflexology</h3>
            <ul>
              <li>Provides relaxation</li>
              <li>Eliminates toxins in the body</li>
              <li>Stimulates circulation</li>
              <li>Relieves aches and pains</li>
              <li>Increases energy</li>
              <li>Improves nerve functions</li>
              <li>Promotes health and well being</li>
              <li>Boosts metabolism</li>
              <li>Encourages speedier healing</li>
              <li>Lowers blood pressure</li>
              <li>Acts as a form of preventative therapy</li>
            </ul>
          </div>
        </div>

        <div className="panel">
          <div className="column">
            <DashedTitle title="History of Reflexology" style={{paddingBottom: '12px'}} />
            <div className="history">
              <p>
                Reflexology is a healing therapy which stems from a wide variety of ancient cultures and dates back thousands of years.<br />
                <br />
                The earliest evidence of hand and foot massage was discovered in the tomb of Ankhmahor in <b>ancient Egypt</b>, which dates back to 2330BC.<br />
                <br />
                The ancient <b>Chinese</b> also worked the hands and feet to maintain health and prevent disease. 'Huang:Di Nei:Jing' (a medical text compiled between 300 and 100BC) states that 'vital energy' runs through 14 major meridians or 'channels' in the body. Of these meridians, 12 begin or end in the tips of the fingers and toes. The text further states that massaging these areas stimulates energy flow and clears congestion - promoting harmony in the body.<br />
                <br />
                Foot massage also plays a significant role in Ayurvedic medicine used in <b>India</b>. This dates back as far as 5000 years ago, making it the oldest system of healing.<br />
                <br />
                The importance of hand and foot massage to ensure good health was also passed down through the oral traditions of <b>native Americans</b> and <b>African tribes</b>.
              </p>
              <div className="column">
                <img src={egypt} alt="Egyptian hieroglyphics" />
                <img src={tapestry} alt="Ancient reflexology tapestry" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutReflexology;