import React, { useState } from 'react';
import {
  useRouteMatch, Link
} from "react-router-dom";
import { FirebaseConsumer } from '../../config/firebase';
import { ProductDetailsSelection } from '../product/product.js';
import { Banner, SecondaryButton } from '../reuseable-components/reuseable-components.js';
import { shopBanner, rareEarthOilsLg, shopBannerLg } from '../../assets/images';
import './shop.css';
import { Warning } from '../warning/warning';

function QuickBuyModal(props) {

  const handleClick = (e) => {
    if (e.target.id === "modal-overlay")
      props.onClose();
  }

  return (
    <FirebaseConsumer>
      {value => {
        return (
          <div id="modal-overlay" className="quick-buy-modal overlay" onClick={(e) => handleClick(e)}>
            <div className="panel column border-box shadow">
              <div
                className="product-image"
                style={{backgroundImage: `url(${value.detailProduct.img})`}}
              >
              </div>
              <ProductDetailsSelection value={value} onSubmit={() => props.onClose()} />
            </div>
          </div>
        )
      }}
    </FirebaseConsumer>
  )
}


function ShopItem(props) {
  const [hoverPanel, setHoverPanel] = useState(false);

  let { url } = useRouteMatch();
  let item = props.item;

  if (props.placeholder) {
    return (
      <div className="product-wrapper placeholder">
        <div
          className="product-image"
        >
        </div>
        <div className="placeholder-name"></div>
        <div className="placeholder-price"></div>
      </div>
    )
  }

  let price = item.sizes[0].price;
  if (!Number.isInteger(price)) {
    price = price.toFixed(2);
  }
  
  return (
    <FirebaseConsumer>
      {value => {
        return (
          <Link
            className="product-wrapper"
            onMouseEnter={() => setHoverPanel(true)}
            onMouseLeave={() => setHoverPanel(false)}
            to={`${url}/${item.key}`}
            onClick={() => value.handleDetail(item.key)}
          >
            <div
              to={`${url}/${item.key}`}
              onMouseEnter={() => setHoverPanel(true)}
              onClick={() => setHoverPanel(true)}
              className={"product-image " + (hoverPanel ? "image-hover" : null)}
              style={{backgroundImage: `url(${item.img})`}}
            >
            </div>
            <h3 className={"product-name " + (hoverPanel ? "underline" : null)}>{item.name}</h3>
            <h3 className="product-price secondary-font">£{price}</h3>
          </Link>
        )
      }}
    </FirebaseConsumer>
  )
}

function Shop() {
  const [quickBuyModal, setQuickBuyModal] = useState(false);

  return (
    <div className="shop">
      {quickBuyModal &&
        <QuickBuyModal onClose={() => setQuickBuyModal(false)} />
      }
      <Banner title="shop" src={shopBannerLg} preview={shopBanner} />
      <FirebaseConsumer>
        {value => {
          if (value.products.length <= 0) {
            return (
              <div className="products-container content">
                <ShopItem placeholder />
                <ShopItem placeholder />
                <ShopItem placeholder />
                <ShopItem placeholder />
                <ShopItem placeholder />
                <ShopItem placeholder />
              </div>
            )
          }
          return (
            <div className="products-container content">
              <Warning>Shop currently down for maintenance.</Warning>
            </div>
          )
        }}
      </FirebaseConsumer>
      <div className="shadow border-box content description">
        <p>
          Rare Earth Oils is a range of natural, organic and ethically-produced wellness products.
          <br /><br />
          As a social enterprise, all profits are fed into the Rare Earth Foundation and their work
           empowering youth in Australia. For further information, visit <a href="https://www.rareearthoils.com/" rel="noopener norefferer" target="_blank">Rare Earth Oils</a>.
        </p>
        <a href="https://www.rareearthoils.com/" rel="noopener norefferer" target="_blank">
          <img src={rareEarthOilsLg} className="rare-earth-oils-logo" alt="Rare Earth Oils" />
        </a>
      </div>
    </div>
  )
}

export default Shop;