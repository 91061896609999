import React from 'react';
import { PRIVACY_POLICY, CONTACT } from '../../constants/routes.js'
import {
  Link,
} from "react-router-dom";
import './footer.css';

function Footer() {
  return (
    <div className="footer-wrapper border-box">
      <div className="wrapper">
        <hr className="thick-hr" />
        <div className="footer-content">
          <div className="col">
            <h3 className="primary-brown">Resources</h3>
            <Link to={PRIVACY_POLICY}>
              <p>Data Protection Policy</p>
            </Link>
          </div>
          <div className="col">
            <h3 className="primary-brown">Social</h3>
            <a href="https://www.facebook.com/soleartherapy/" rel="noopener norefferer" target="_blank">
              <p>Facebook</p>
            </a>
            <a href="https://www.instagram.com/soleartherapy" rel="noopener norefferer" target="_blank">
              <p>Instagram</p>
            </a>
          </div>
          <div className="col">
            <h3 className="primary-brown">Contact</h3>
            <Link to={CONTACT}>
              <p>soleartherapy@gmail.com</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;