import React, { useState } from 'react';
import * as ROUTES from '../../constants/routes.js'
import * as IMAGES from '../../assets/images';
import { SVGIcon } from '../../assets/icons';
import ProgressiveImage from "react-progressive-image-loading";
import { ChevronLeft, ChevronRight } from 'react-feather';
import { ContactForm, ContactDetails } from '../contact/contact.js';
import { ServicesPanels } from '../services/services.js';
import { DashedTitle } from '../reuseable-components/reuseable-components.js';
import {
  Link
} from "react-router-dom";
import './home.css';

function Panel(props) {
  const divStyle = {
    backgroundImage: `url(${props.img})`,
    height: props.height
  }

  if (props.overflow && props.description) {
    return (
      <div className="panel-container">
        <div
          className="image-container"
          style={divStyle}
        >
        </div>
        <div className="text-container overflow">
          <h3>{props.title}</h3>
          <p>
            {props.description}
          </p>
        </div>
      </div>
    )
  }

  return (
    <div
      className="image-container panel-container"
      style={divStyle}
    >
      <div className="text-container">
        <h3>{props.title}</h3>
        {props.description && 
          <p>
            {props.description}
          </p>
        }
      </div>
    </div>
  )
}

function Carousel(props) {
  const [ selectedItem, setSelectedItem ] = useState(0);
  let items = props.items;
  items = items.slice(1, 6);

  function handleChevronClick(i) {
    if (selectedItem + i === -1) {
      return setSelectedItem(items.length - 1);
    }
    if (selectedItem + i === items.length) {
      return setSelectedItem(0);
    }
    return setSelectedItem(selectedItem + i);
  }

  function renderThumbnailIcon(gender) {
    if (gender === 'female') {
      return (
        <SVGIcon name="woman" width={70} fill="#6F1D00" stroke="#6F1D00" />
      )
    }
    return (
      <SVGIcon name="man" width={70} fill="#6F1D00" stroke="#6F1D00" />
    )
  }

  return (
    <div className="carousel-container column">
      <div className="row carousel">
        <ChevronLeft onClick={() => handleChevronClick(-1)} size={32} color="#F56600" className="chevron" />
        <div className="panel shadow">
          <div className="thumbnail shadow">
            {renderThumbnailIcon(items[selectedItem].gender)}
          </div>
          <p>
            <SVGIcon name="quote" width={15} fill="#6F1D00" style={{paddingRight: '16px'}} />
            {items[selectedItem].quote}
            <SVGIcon name="closed-quote" width={15} fill="#6F1D00" style={{paddingLeft: '16px'}} />
          </p>
          <h3>- {items[selectedItem].name} <span>{items[selectedItem].from}</span></h3>
        </div>
        <ChevronRight onClick={() => handleChevronClick(1)} size={32} color="#F56600" className="chevron" />
      </div>
      <div className="row selection-container">
        {items.map((item, key) => (
          <div
            onClick={() => setSelectedItem(key)}
            style={(selectedItem === key) ? {backgroundColor: '#F56600'} : null}
            key={key}
            className="selection"
          />
        )
        )}
      </div>
    </div>
  )
}

function Home(props) {
  return (
    <div className="home-wrapper column">
      <div className="content column">
        <div className="landing-page">
          <ProgressiveImage 
            preview={IMAGES.heroImageLoad}
            src={IMAGES.heroImage}
            render={
              (src, style) => <div
                className="image-container hero-image panel-container"
                style={{backgroundImage: `url(${src})`}}
              >
                <div className="text-container">
                  <h2 className="primary-brown">Step towards better health</h2>
                  <h3 style={{fontSize: '18px'}}>
                    Prioritise your health and wellbeing, book a reflexology treatment today.
                  </h3>
                </div>
              </div>
            }
          />
        </div>
        {/* Reflexology section */}
        <div className="reflexology">
          <div className="section-header primary-brown">
            <DashedTitle title="How can reflexology help you?" />
          </div>
          <div className="panels">
            <div className="border-box wrapper">
              <Panel
                img={IMAGES.stressed}
                title="Stressed?"
                height="220px"
                overflow={true}
                description="Reflexology treats stress by inducing relaxation, releasing muscular tension and improving overall health and wellbeing."
              />
            </div>
            <div className="border-box wrapper">
              <Panel
                img={IMAGES.pregnant}
                title="Pregnant?" 
                height="220px"
                overflow={true}
                description="Reflexology is safe throughout pregnancy and relieves common ailments like backache, nausea, insomnia and bladder problems."
              />
            </div>
            <div className="border-box wrapper">
              <Panel
                img={IMAGES.injured}
                title="Injury Recovery?" 
                height="220px"
                overflow={true}
                description="Reflexology boosts the immune system which aids the healing process and promotes a smoother, faster recovery."
              />
            </div>
          </div>
          <Link to={ROUTES.ABOUT_REFLEXOLOGY} className="button secondary-button">
            Find out more
          </Link>
        </div>

        <div className="therapist">
          <div className="section-header primary-brown">
            <DashedTitle title="Our Therapist" />
          </div>

          <div className="about-me">
            <div className="shadow quote">
              <DashedTitle title="Susan Brown (MAR)" color={'#6F1D00'} />
              <p>
                <SVGIcon name="quote" width={15} fill="#6F1D00" style={{paddingRight: '16px'}} />
                I offer a completely bespoke service with an understanding of my client's individual treatment needs. I work closely with my clients so that together we can address and manage health issues and wellness needs. I wholeheartedly believe in the benefits of holistic therapies and am thankful that I can impart these benefits onto my amazing clients. I often receive feedback about the improvements experienced after having a treatment, which makes what I do all the more worthwhile. I cherish spreading the joy of reflexology to people from all walks of life.
                <SVGIcon name="closed-quote" width={15} fill="#6F1D00" style={{paddingLeft: '16px'}} />
              </p>
              <img
                src={IMAGES.AOR} alt="Association of Reflexologists" />
            </div>
            <div
              className="image-container panel-container"
              style={{backgroundImage: `url(${IMAGES.therapist})`}}
            >
            </div>
          </div>
        </div>

        <div className="testimonial-samples column">
          <div className="section-header primary-brown">
            <DashedTitle title="Testimonials" />
          </div>
          <Carousel items={props.testimonials} />
          <Link to={ROUTES.TESTIMONIALS} className="secondary-button button">
            View all testimonials
          </Link>
        </div>

        <div className="services column">
          <div className="section-header primary-brown">
            <DashedTitle title="Services" />
          </div>
          <ServicesPanels />
          <p>*The first reflexology treatment includes an in-depth face-to-face consultation. A brief telephone consultation must also take place prior to this.</p>
        </div>

        <div className="contact-section column">
          <div className="section-header primary-brown">
            <DashedTitle title="Contact Me" />
          </div>
          <p className="instruction">
            To schedule a <b className="primary-brown">free telephone consultation</b>, book a treatment, or ask any questions, please complete the attached form.
          </p>
          <div className="wrapper">
            <div className="shadow border-box">
              <h2 className="primary-brown">Contact Form</h2>
              <ContactForm />
            </div>
            <ContactDetails />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home;