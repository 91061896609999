import React, { useState, useEffect } from 'react';
import { SHOP } from '../../constants/routes.js';
import { UseWindowSize } from '../hooks/reuseable-hooks.js';
import {
  Link,
  Redirect
} from "react-router-dom";
import { ArrowLeft, ShoppingBag } from 'react-feather';
import { FirebaseConsumer } from '../../config/firebase';
import './product.css';

function ProductGallery(props) {
  return (
    <div className="product-gallery">
      <div
        className="product-image"
        style={{backgroundImage: `url(${props.src})`}}
      >
      </div>
    </div>
  )
}

export function ProductDetailsSelection(props) {
  const [selectedSize, setSelectedSize] = useState(props.value.detailProduct.sizes[0]);
  const [inStockPanel, setInStockPanel] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const { value } = props;

  const windowSize = UseWindowSize();

  let price = selectedSize.price;
  if (!Number.isInteger(price)) {
    price = price.toFixed(2);
  }

  useEffect(() => {
    setSelectedSize(value.detailProduct.sizes[0]);
    setQuantity(1);

  }, [value.detailProduct]);

  function handleAddToBag(value) {
    if ((Number(quantity) + selectedSize.count) > selectedSize.stock) {
      return setInStockPanel(true);
    }

    value.addToBag(value.detailProduct.key, selectedSize.key, quantity);
    value.openModal(value.detailProduct.key, selectedSize.key, quantity);
    if (props.onSubmit) {
      props.onSubmit();
    }
  }

  return (
    <div className="product-detail-selection column">
      <div className="row space-between">
        <h3>{value.detailProduct.name}</h3>
        <h3 className="secondary-font">£{price}</h3>
      </div>
      <hr className="thick-hr" />

      {value.detailProduct.sizes.length > 1 &&
        <div>
          <h3 className="input-header">Size</h3>
          <div className="row">
            {value.detailProduct.sizes.map((item) => (
              <div
                key={item.key}
                className={"product-size " + (selectedSize.key === item.key ? 'selected' : null)}
                onClick={() => setSelectedSize(item)}
              >
                <p>{item.size}</p>
              </div>
            ))}
          </div>
        </div>
      }
      
      <h3 className="input-header">Quantity</h3>
      <input
        value={quantity}
        className="quantity-input"
        onChange={e => setQuantity(e.target.value)}
        type="number"
        min="1"
        max={selectedSize.stock}
      />
      <button
        disabled={!value.storeAvailability || selectedSize.stock <= 0}
        onClick={()=> {handleAddToBag(value)}}
        className="primary-button"
      >
        Add to Bag <ShoppingBag style={{marginLeft: '18px'}} size={18} />
      </button>
      
      <div className="row space-between availability">
        <h3>Availability</h3>
        <div className="row">
          <p>Store Open</p>
          <div className={"availability-icon " + (value.storeAvailability ? "yes" : "no")}></div>
        </div>
        <div
          className="row in-stock"
          onClick={() => windowSize.width < 1024 ? setInStockPanel(!inStockPanel) : null }
          onMouseEnter={() => setInStockPanel(true)}
          onMouseLeave={() => setInStockPanel(false)}
        >
          <p>In Stock</p>
          <div className={"availability-icon " + (selectedSize.stock > 0 ? "yes" : "no")}></div>
          <div className={"in-stock-panel " + (inStockPanel ? "shadow" : "hidden")}>
            <p>
              Item's in stock: {selectedSize.stock}<br />
              Item's in bag: {selectedSize.count}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

function ProductIngredients(props) {
  const ingredients = props.ingredients;
  return (
    <div className="column ingredients">
      <h3 className="primary-brown">Ingredients</h3>
      <p>
        {ingredients.map((item, key) => (
          <span key={key}> {item}{(key !== ingredients.length - 1) && ', '}</span>
        ))}
      </p>
    </div>
    
  )
}

function ProductDescription(props) {
  const description = props.description;
  return (
    <div className="product-description">
      <h3 className="primary-brown">About this product</h3>
      {description.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </div>
  )
}

function OtherProducts(props) {
  const value = props.value
  const productArray = [];
  for (let i=1; i < 5; i++) {
    let key = value.detailProduct.key + i;
    // If product out of array loop around
    if (key >= value.products.length) {
      key -= value.products.length;
    }
    const product = value.getItem(key);
    productArray.push(product);
  }
 
  return (
    <div className="row other-products-container">
      {productArray.map((product) => (
        <Link
          key={product.key}
          className="other-product-container"
          onClick={() => value.handleDetail(product.key)}
          to={`${SHOP}/${product.key}`}
        >
          <div className="other-product-img-bg">
            <div
              className="other-product-img"
              style={{backgroundImage: `url(${product.img})`}}
            >
            </div>
          </div>
          <h3>{product.name}</h3>
        </Link>
      ))}
    </div>
  )
}

function Product() {
  const windowSize = UseWindowSize();
  return (
    <FirebaseConsumer>
      {value => {
        if (value.detailProduct) {
          const { description, img, ingredients } = value.detailProduct;
          if (windowSize.width >= 1024) {
            return (
              <div className="product">
                <div className="row content">
                  <div className="column col-1">
                    <div className="">
                      <ProductDetailsSelection value={value} />
                    </div>
                    <ProductDescription description={description} />
                    <h3 className="primary-brown" style={{marginBottom: '0px', marginTop: '24px'}}>Other Products</h3>
                    <hr style={{width: '100%', marginBottom: '16px'}} className="thin-hr" />
                    <OtherProducts value={value} />
                  </div>
                  <div className="column col-2">
                    <ProductGallery src={img} />
                    <ProductIngredients ingredients={ingredients} />
                  </div>
                </div>
              </div>
            )
          } else {
            return (
              <div className="product column">
                <ProductGallery src={img} />
                <Link to={SHOP} className="mob-back-arrow">
                  <ArrowLeft />
                </Link>
                <div className="content column">
                  <ProductDetailsSelection value={value} />
                  <ProductDescription description={description} />
                  <ProductIngredients ingredients={ingredients} />
                  <h3 className="primary-brown" style={{marginBottom: '0px'}}>Other Products</h3>
                  <hr style={{width: '100%', marginBottom: '16px'}} className="thin-hr" />
                  <OtherProducts value={value} />
                </div>
              </div>
            )
          }
        } else {
          return (
            <Redirect to={SHOP} />
          )
        }
      }}
    </FirebaseConsumer>
  )
}

export default Product;