import React from 'react'
import { useState } from 'react'

const styles = {
  inputContainer: {
    position: 'relative',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '0.5rem',
    border: '1px solid #a0aec0',
    borderRadius: '0.25rem',
    padding: '0.5rem',
    minHeight: '40px'
  },
  
  inputLabel: {
    display: 'block',
    color: '#a0aec0',
    fontSize: '0.75rem'
  },
  
  input: {
    display: 'block',
    width: '100%',
    border: 'none'
  },
    // input:focus {
    //   outline: none;
    // }
  
  rightContainer: {
    position: 'absolute',
    top: '50%',
    marginTop: '-12px',
    right: 0,
    marginRight: '12px'
  },
}


export default function StyledInput(props) {
  const [ focused, setFocused ] = useState(false);
  const { id, label, onChange, value, type, required, rows, style, editable=true } = props;

  const renderInput = () => {
    if (rows) {
      return (
        <textarea
          style={styles.input}
          onFocus={() => setFocused(!focused)}
          onBlur={() => setFocused(!focused)}
          id={id}
          required={required}
          placeholder={label}
          disabled={!editable}
          type={type}
          rows={rows}
          onChange={(e) => onChange(e)}
          value={value}
        >
        </textarea>
      )
    }

    return (
      <input
        style={styles.input}
        onFocus={() => setFocused(!focused)}
        onBlur={() => setFocused(!focused)}
        id={id}
        disabled={!editable}
        required={required}
        placeholder={label + (!required ? " (optional)" : "")}
        type={type}
        value={value}
        onChange={(e) => onChange(e)}
      />
    )
  }
  
  return (
    <div
      style={{...style, ...styles.inputContainer, boxShadow: focused ? '0 0 4px #F56600' : 'none'}}
    >
      <label
        style={{...styles.inputLabel ,  display: value ? 'block' : 'none'}}
      >
        {label + (!required ? " (optional)" : "")}
      </label>
      {renderInput()}
    </div>
  )
}