import React, { useState } from 'react';
import { CONTACT } from '../../constants/routes.js';
import { Banner } from '../reuseable-components/reuseable-components';
import { Plus, Minus } from 'react-feather';
import { faqBanner, faqBannerLoad } from '../../assets/images';
import {
  Link
} from "react-router-dom";
import './faq.css';

const faqs = [
  {
    question: 'What does reflexology help with?',
    answer: 'Reflexology may be helpful in relieving a wide range of ailments. It is most beneficial in restoring balance and harmony in the body and releasing tension. Reflexology helps facilitate a deep state of relaxation, calm the emotions and produce a serene mind. Research studies support many of these benefits.'
  },
  {
    question: 'What illnesses can reflexology treat?',
    answer: 'Although reflexology is not used to diagnose disease, millions of people around the world use it to compliment other treatments especially when addressing conditions associated with cancer treatment, migraines & headaches, circulatory issues, PMS and kidney function. It is particularly beneficial for sufferers of stress and anxiety.'
  },
  {
    question: "Can I have reflexology when I'm pregnant",
    answer: 'It is advisable to find a reflexologist who has experience and has undertaken a course specifically in treating pregnant clients.'
  },
  {
    question: 'Can a reflexologist diagnose illness?',
    answer: 'A professional reflexologist is not qualified to diagnose. They may, however come across something which may concern them in which case they will always advise the client to seek advice from their GP.'
  },
  {
    question: 'Does it hurt?',
    answer: 'A reflexology treatment will only hurt or feel uncomfortable when the reflex area being treated is congested. As the condition improves with each session so will the soreness on the corresponding reflex. A well trained reflexologist will always adapt the treatment to suit the individual.'
  },
  {
    question: 'How often do I need a treatment?',
    answer: 'In general, results from reflexology are often subtle and cumulative. Therefore, a client is more likely to see greater benefits from regular sessions (at least one every two weeks). I always advise clients to have a "top up" treatment once a month once their symptoms subside or become manageable.'
  }
]

function Question(props) {
  const [showAnswer, setShowAnswer] = useState(false);

  function displayIcon() {
    if (showAnswer) {
      return <Minus color={'#FF6600'} />
    }
    return <Plus color={'#FF6600'} />
  }

  return (
    <div
      className="question-container"
      onClick={() => setShowAnswer(!showAnswer)}
    >
      <div className="row">
        <div className="icon-container">
          {displayIcon()}
        </div>
        <p className="question">{props.question}</p>
      </div>
      <p className={"answer " + (showAnswer ? "open" : null)}>{props.answer}</p>
    </div>
  )
}

function FAQ() {
  return (
    <div className="faq column">
      <Banner title="FAQ" src={faqBanner} preview={faqBannerLoad} />
      <div className="content column border-box">
        {faqs.map((item, key) => (
          <div key={key} style={{width: '100%'}}>
            <Question question={item.question} answer={item.answer} />
            <hr width='95%' className="thin-hr" />
          </div>
        ))}
        <p>
          Cant find what you are looking for? 
          <Link to={CONTACT}> Contact Me </Link> 
          or find out more at
          {" "}
          <a
            target="_blank"
            href="https://www.aor.org.uk/"
            rel="noopener noreferrer"
            style={{textDecoration: "underline"}}
          >
            https://www.aor.org.uk/
          </a>
        </p>
      </div>
    </div>
  )
}

export default FAQ;