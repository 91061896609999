import React from 'react';
import { Banner, DashedTitle } from '../reuseable-components/reuseable-components';
import {
  Link
} from "react-router-dom";
import { expectBanner, expectBannerLoad } from '../../assets/images';
import './what-to-expect.css';
import { FAQ, SHOP } from '../../constants/routes';

function WhatToExpect() {
  return (
    <div className="what-to-expect column">
      <Banner title="What to Expect" src={expectBanner} preview={expectBannerLoad} />
      <div className="content column border-box">
        <div className="section">
          <DashedTitle title="Before your treatment" />
          <p>
            Every client must undergo a two-step consultation process prior to their first reflexology treatment. These steps are as follows:
          </p>
          <ol>
            <li>A brief telephone consultation</li>
            <li>An in-depth face to face consultation</li>
          </ol>
          <p>
            These conversations ensure that Soleartherapy can meet the individual needs of each client. These consultations also provide clients with the opportunity to ask any further questions they may have and address any concerns or worries.<br />
            <br />
            Do you have any questions about reflexology which you would like answered before taking the next step? Why not visit our <Link to={FAQ}>FAQ</Link> page.
          </p>
        </div>
        <div className="section">
          <DashedTitle title="During your treatment" />
          <p>
            Soleartherapy strives to make every client feel relaxed and comfortable during their treatment. <br />
            <br />
            Reflexology treatments begin with a warm footbath and proceed with clients lying back in our comfortable reflexology chair for the remainder of the session. Oils and aromatherapy are used throughout to further induce relaxation. Each Reflexology treatment lasts for 1 hour.<br />
            <br />
            The Natural Lift Facial Massage is carried out on a massage table, with clients laying flat on their back. These sessions also last for 1 hour and include the use of aromatherapy and specialty skincare products.<br/>
            <br/>
            During treatments, Soleartherapy use Rare Earth Oils, a range of natural, organic and cruelty-free wellness products. Soleartherapy is the only reflexology practice in the UK to use these unique products. Should clients wish to purchase any of the products used, they can do so after their treatment, or by visiting our <Link to={SHOP}>Shop</Link>.
          </p>
        </div>
        <div className="section">
          <DashedTitle title="After your treatment" />
          <p>
            Make time to relax for the remainder of your day following a treatment and drink plenty of water to flush out any toxins.<br />
            <br />
            For optimal results, it is best to avoid the following after a reflexology treatment:
          </p>
          <ul>
            <li>Caffeine</li>
            <li>Alcohol</li>
            <li>Drugs</li>
            <li>Smoking</li>
            <li>Strenuous activity</li>
            <li>Heavy meals</li>
          </ul>
          <p>
            Occasionally, clients may suffer with 'the healing crisis'. This is perfectly natural and is a further sign that your treatment is working. Symptoms may include:
          </p>
          <ul>
            <li>Mild headache</li>
            <li>Tiredness</li>
            <li>Feeling light-headed</li>
            <li>Needing to visit the toilet more often</li>
          </ul>
          <p>
            It is possible that your symptoms may feel worse or you have a heightened emotional response to situations. These are normal reactions and should pass within 24-48 hours.
          </p>
        </div>
      </div>
    </div>
  )
}

export default WhatToExpect;