import React, { useState } from 'react';
import firebase from 'firebase/app';
import { SVGIcon } from '../../assets/icons';
import { contactBanner, contactBannerLg } from '../../assets/images';
import ReactLoading from 'react-loading';
import {
  Link
} from "react-router-dom";
import { Banner } from '../reuseable-components/reuseable-components';
import StyledInput from '../reuseable-components/styled-input';
import Axios from 'axios';
import './contact.css';
import { PRIVACY_POLICY } from '../../constants/routes';

function ContactDetails() {
  return (
    <div className="column contact-details-container">
      <div className="row email-address-container">
        <SVGIcon name="mail" width={75} stroke="#6F1D00" />
        <div className="column contact-details">
          <h3 className="primary-brown">Email Address</h3>
          <p>soleartherapy@gmail.com</p>
        </div>
      </div>
      <div className="row">
        <SVGIcon name="location" width={75} stroke="#6F1D00" />
        <div className="column contact-details">
          <h3 className="primary-brown">Location</h3>
          <p>
            As a mobile therapist, I am only able to service clients within the following areas:
          </p>
          <ul>
            <li>Benfleet</li>
            <li>Leigh</li>
            <li>Basildon</li>
            <li>Rayleigh</li>
            <li>Castle Point</li>
          </ul>
          <br />
          <p>
            If you are outside of the mobile service area, or prefer to have treatments outside of your home, I also provide treatments at
            <a href="https://www.facebook.com/rougenailsandbeautysalon/" target="_blank"> Rouge</a> and <a href="https://www.facebook.com/thebeautyspothadleigh" target="_blank">The Beauty Spot</a>.
            You can book a treatment by contacting them directly or by filling in the Contact form.
          </p>
        </div>
      </div>
    </div>
  )
}

function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [emailStatus, setEmailStatus] = useState("");
  const [formDisabled, setFormDisabled] = useState(false);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setFormDisabled(true);
    const data = {
      name: name,
      email: email,
      number: number,
      message: message
    };
    Axios.post('https://europe-west1-soleartherapy-b43f7.cloudfunctions.net/submit', data)
      .then(res => {
        if (res.data.isEmailSend) {
          emailSent();
        }
        if (firebase) {
          return firebase
            .database()
            .ref('contacts')
            .push(data);
        }
      })
      .catch(error => {
        emailSent(error);
      });
  }

  const resetFields = () => {
    setName("");
    setEmail("");
    setNumber("");
    setMessage("");
  }

  const emailSent = (error) => {
    setFormDisabled(false);

    if (error) {
      setEmailStatus("Something went wrong, Error Message:" + error)
    } else {
      resetFields();
      setEmailStatus("Message has been successfully delivered.");
    }
    
  }

  const submitButton = () => {
    if (formDisabled) {
      return (
        <div className="primary-button button active">
          <ReactLoading type={'spinningBubbles'} color={'#FFFFFF'} height={30} width={30} />
        </div>
      )
    }
    return (
      <input
        type="submit"
        value="Submit"
        className="primary-button button"
      />
    )
  }

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <StyledInput
        id='name'
        label='Your Name'
        onChange={e => setName(e.target.value)}
        value={name}
        type="text"
        required={true}
      />
      <StyledInput
        id='email'
        label='Your Email Address'
        onChange={e => setEmail(e.target.value)}
        value={email}
        type="email"
        required={true}
      />
      <StyledInput
        id='number'
        label='Your Phone Number'
        onChange={e => setNumber(e.target.value)}
        value={number}
        type="tel"
        required={false}
      />
      <StyledInput
        id='message'
        label='Your Message'
        onChange={e => setMessage(e.target.value)}
        value={message}
        rows={6}
        type="text"
        required={true}
      />
      <div className="optional-message">
        <p>
          * Opting to leave a phone number means that I can call you at my earliest convenience.
        </p>
        <p>
          <b className="primary-brown">Please note: </b>
          Your contact details will not be used for any marketing purposes. For more information, view our <Link to={PRIVACY_POLICY}>Privacy Policy</Link>.
        </p>
      </div>
      {submitButton()}
      <p>{emailStatus}</p>
    </form>
  )
}

function Contact() {
  return (
    <div className="contact-container column">
      <Banner title="Contact Me" src={contactBannerLg} preview={contactBanner} />
      <div className="content">
        <div className="shadow">
          <h2 className="primary-brown">Contact Form</h2>
          <ContactForm />
        </div>
        <ContactDetails />
      </div>
    </div>
  )
}

export { ContactForm, ContactDetails };
export default Contact;