export const HOME = '/';
export const ABOUT_REFLEXOLOGY = '/about-reflexology';
export const WHAT_TO_EXPECT = '/what-to-expect';
export const FAQ = '/faq';
export const SERVICES = '/services';
export const TESTIMONIALS = '/testimonials';
export const SHOP = '/shop';
export const CONTACT = '/contact';
export const SHOPPING_BAG = '/shopping-bag';
export const ADMIN = '/admin';
export const CHECKOUT = '/checkout';
export const PRIVACY_POLICY = '/data-protection-policy';
export const SUCCESS = '/success';

